<template>
    <section :class="this.$store.state.currentLang" v-if="data != null" id="friends">
        <div class="container">
            <div class="top-span">{{ data?.top_title }}</div>
            <div class="title-container">
                <h2 v-html="data?.title"></h2>
                <div class="btn-container desktop">
                    <p v-html="data?.text_button"></p>
                    <div class="map-btn" v-html="data?.button" @click="openPopupMup"></div>
                </div>
            </div>
            <div class="friends-list">
                <div class="friends-item" v-for="item in data?.cards">
                    <span v-html="item?.title"></span>
                    <p v-html="item?.text"></p>
                </div>
            </div>
            <div class="btn-container mobile">
                <p v-html="data?.text_button"></p>
                <div class="map-btn" v-html="data?.button" @click="openPopupMup"></div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null
    },
    methods: {
        openPopupMup() {
            this.$store.commit("setPopupMap", true);
            document.querySelector('body').style.overflow = "hidden";
        }
    }
}
</script>
<style>
    .title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .map-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        border-radius: 16px;
        background: var(--blue);
        color: var(--white);
        font-size: 14px;
        font-weight: 700;
        line-height: 132%;
        width: max-content;
        cursor: pointer;
        transition: 0.5s;
    }

    .map-btn:hover {
        background: rgba(10, 151, 160, 0.84);
    }

    #friends h2 {
        max-width: 700px;
    }

    .btn-container {
       max-width: 253px;
       display: flex;
       flex-direction: column;
       gap: 22px;
    }

    .btn-container p {
        font-size: 18px;
        font-weight: 600;
        line-height: 124%;
        letter-spacing: 0;
    }

    .friends-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 48px;
    }

    .friends-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        padding: 24px;
        border-radius: 24px;
        background: rgb(253, 245, 230);
    }

    .friends-item span {
        font-size: 18px;
        font-weight: 700;
        line-height: 132%;
    }

    .friends-item p {
        color: rgba(18, 18, 18, 0.64);
        font-size: 16px;
        font-weight: 500;
        line-height: 136%;
    }

    @media screen and (max-width: 1200px) {
        .title-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .friends-list {
            grid-template-columns:  1fr;
            margin-top: 0px;
        }

        #friends h2 {
            max-width: 100%;
            font-size: 26px;
            font-weight: 700;
            line-height: 124%;
            margin-bottom: 36px;
        }

        .btn-container {
            margin-top: 42px;
            max-width: 100%;
        }

        .map-btn {
            margin-top: 16px;
            width: 100%;
            font-size: 14px;
            font-weight: 700;
            line-height: 132%;
        }

        .btn-container p {
            max-width: 253px;
            font-size: 18px;
            font-weight: 600;
            line-height: 124%;
        }

    }
</style>