<template>
    <main>
        <section id="authorization" :class="this.$store.state.currentLang">
            <div class="container">
                <div class="autho-container">
                    <div class="autho-left">
                        <div class="logo-text">
                            <img
                                :src="require('@/assets/img/logo_m.png')"
                                alt=""
                            />
                            <h1 v-html="data?.avt.title"></h1>
                        </div>
                        <div class="autho-left-lk pc" v-html="data?.avt.text_link"></div>
                    </div>
                    <form class="autho-right">
                        <div class="input-item">
                            <input
                                type="text"
                                name=""
                                id=""
                                :placeholder="data?.avt.login"
                                v-model="id_email"
                                :class="
                                    error_id_email == '' ? '' : 'autho-error'
                                "
                            />
                            <span
                                :class="
                                    error_id_email == '' ? '' : 'autho-error'
                                "
                                >{{ error_id_email }}</span
                            >
                        </div>
                        <div class="input-item">
                            <input
                                type="password"
                                name=""
                                id=""
                                :placeholder="data?.avt.password"
                                v-model="password"
                                :class="
                                    error_password == '' ? '' : 'autho-error'
                                "
                            />
                            <span
                                :class="
                                    error_password == '' ? '' : 'autho-error'
                                "
                                >{{ error_password }}</span
                            >
                        </div>
                        <div class="btn-autho-container">
                            <Button
                                class="btn-autho"
                                :btn_class="'blue'"
                                :value="data?.avt.btn"
                                @click="createAuthorization"
                            />
                            <div class="police" v-html="data?.avt.police"></div>
                        </div>
                        <div class="autho-left-lk mob" v-html="data?.avt.text_link"></div>
                    </form>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import Button from "@/components/UI/Button.vue";
import {
    replaceNumberForPaste,
    replaceToLatters,
    validateEmail,
    isNumeric,
} from "@/assets/js/helper.js";
import axios from "axios";
import { sendComagic } from "@/assets/js/comagic.js";
import regApi from "@/assets/js/api/registration/registration.js";
import md5 from "md5";
export default {
    components: {
        Button,
    },
    props: {
        data: null
    },
    data() {
        return {
            id_email: "",
            password: "",
            error_id_email: "",
            error_password: "",
            is_id: true,
            is_validated: false,
            error_order: "",
            response: null
        };
    },
    methods: {
        async createAuthorization() {
            this.error_order = "";
            if (this.validate()) {
                let form_data = new FormData();
                let data = {};
                if (this.is_id == true) {
                    data = {
                        client: "taovita-mobile",
                        method: "client/visitor/authorize",
                        login: this.id_email,
                        passwd: this.password,
                    };
                } else {
                    data = {
                        client: "taovita-mobile",
                        method: "client/visitor/authorize",
                        email: this.id_email,
                        passwd: this.password,
                    };
                }
                form_data.append(
                    "url",
                    "https://taovita.ru/do.rapi/request.json"
                );
                form_data.append("data", JSON.stringify(data));

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://mayaydyryssova.com/v1/methods/taovita_auth",
                    data: form_data,
                };
                axios
                    .request(config)
                    .then((response) => {
                        if (response.data?.message == "ok") {
                            window.location =
                                "https://taovita.ru/do.rapi/auth?token=" +
                                response.data?.token +
                                "&done=https://taovita.ru/office/dashboard";
                            this.error_id_email = "";
                            this.error_password = "";
                            this.is_validated = false;
                            this.error_order = "";
                        }
                        this.response = response;
                        this.validate();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        validate() {
            let req_field = "";
            let id_email_field = "";
            let password_field = "";
            let not_found = "";
            if (this.$store.state.currentLang == "ru") {
                req_field = "Обязательное поле";
                id_email_field = "Введите существующую почту/ID";
                not_found = "Неправильный логин или пароль"
                password_field = "пароль должен быть более 8-ми символов";
            }

            // if (this.$store.state.currentLang == "kz") {
            //   req_fild = "Міндетті өріс";
            //   phone_error = "Бар нөмірді енгізіңіз";
            // }

            // if (this.$store.state.currentLang == "uz") {
            //   req_fild = "Majburiy maydon";
            //   phone_error = "Mavjud raqamni kiriting";
            // }

            // if (this.$store.state.currentLang == "uz_cyr") {
            //   req_fild = "Керакли майдон";
            //   phone_error = "Мавжуд рақамни киритинг";
            // }

            // if (this.$store.state.currentLang == "kg") {
            //   req_fild = "Милдеттүү талаа";
            //   phone_error = "Учурдагы Номерди киргизиңиз";
            // }

            // if (this.$store.state.currentLang == "en") {
            //   req_fild = "Required field";
            //   phone_error = "Enter an existing numbe";
            // }

            // if (this.$store.state.currentLang == "tj") {
            //   req_fild = "Майдони ҳатмӣ";
            //   phone_error = "Раками дохил кунед";
            // }

            this.error_id_email = "";
            this.error_password = "";

            if (this.password == "") {
                this.error_password = req_field;
            } else if (this.password.length < 8) {
                this.error_password = password_field;
            }

            //   if (this.id_email == "") {
            //     this.error_id_email = req_field;
            //   } else if (!validateEmail(this.id_email) || !/^\d+$/.test(this.id_email)) {
            //     console.log(!validateEmail(this.id_email), !/^\d+$/.test(this.id_email));
            //     this.error_id_email = id_email_field;
            //     return;
            //   } else {
            //     this.error_id_email = "";
            //   }
            if (this.id_email == "") {
                this.error_id_email = req_field;
            }
            
            if (this.response?.data.message == "no data found") {
                this.error_id_email = not_found;
                this.response = null
                return;
            } else if (this.id_email.match("@")) {
                if (!validateEmail(this.id_email)) {
                    this.error_id_email = id_email_field;
                    return;
                } else this.is_id = false;
            } else if (!/^\d+$/.test(this.id_email)) {
                if (this.response?.data.message == "no data found") {
                    this.error_id_email = id_email_field;
                    this.response = null
                }
                return;
            } else {
                this.error_id_email = "";
            }

            this.is_validated = true;

            return this.error_password == "" && this.error_id_email == "";
        },
        ...mapMutations({
            setLoading: "page/setLoading",
        }),
    },
    watch: {
        currentLang() {
            this.error_password = "";
            this.error_id_email = "";
        },
        id_email(new_value) {
            this.id_email = new_value;
            if (this.is_validated) {
                this.validate();
            }
        },
        password(new_value) {
            this.password = new_value;
            if (this.is_validated) {
                this.validate();
            }
        },
    },
    computed: {
        ...mapState({
            currentLang: (state) => state.currentLang,
        }),
    },
};
</script>
<style>
#authorization {
    padding: 170px 0px 164px;
    background-color: #c2e8e1;
    margin: 0;
}

.autho-container {
    max-width: 1080px;
}

.autho-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.autho-left h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 124%;
}

.autho-left-lk {
    color: rgba(54, 54, 54, 0.64);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
}

.autho-left-lk a {
    color: var(--blue);
    text-decoration: underline;
    font-weight: 700;
    transition: 0.5s;
}

.autho-left-lk a:hover {
    color: rgba(10, 151, 160, 0.84);
}
.autho-left-lk.mob {
    display: none;
}
.autho-container {
    display: flex;
    flex-direction: row;
    gap: 75px;
    background-color: var(--white);
    border-radius: 32px;
    padding: 48px;
}

.autho-right {
    display: flex;
    flex-direction: column;
    gap: 35px;
    max-width: 40%;
}
.input-item {
    position: relative;
}

.input-item input {
    box-sizing: border-box;
    border: 1px solid rgba(54, 54, 54, 0.24);
    border-radius: 12px;
    background: rgba(54, 54, 54, 0.08);
    font-size: 12px;
    font-weight: 600;
    line-height: 132%;
    text-transform: uppercase;
    padding: 16px;
    width: 100%;
    transition: 0.5s;
}

.input-item input::placeholder {
    font-size: 12px;
    font-weight: 600;
    line-height: 132%;
}

.input-item input:focus {
    border-color: #363636;
    outline: #363636;
    color: #363636;
}
.input-item input:not(:placeholder-shown) {
    border-color: #363636;
    outline: #363636;
}

.input-item input.autho-error {
    border-color: var(--errors);
}

.input-item span {
    position: absolute;
    font-size: 11px;
    width: 100%;
    top: calc(100% + 4px);
    left: 0;
    transition: 0.5s;
    color: var(--errors);
}

.input-item span.autho-error {
    font-family: Montserrat;
    font-weight: 500;
    line-height: 132%;
}
.btn-autho {
    color: var(--white);
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 132%;
    border-radius: 12px;
    background: rgb(10, 151, 160);
    text-align: center;
    margin: 17px 0 8px;
    text-transform: uppercase;
    width: auto;
}

.police {
    color: rgba(54, 54, 54, 0.64);
    font-size: 8px;
    font-weight: 400;
    line-height: 136%;
}

.police a {
    color: rgba(54, 54, 54, 0.64);
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0.02em;
    text-align: left;
    text-decoration: underline;
}

#authorization.kz .btn-autho {
    font-size: 15px;
}

@media screen and (max-width: 1200px) {
    #authorization {
        padding: 124px 0 100px;
    }
    .autho-right {
        max-width: none;
        gap: 32px;
    }
    .autho-container {
        max-width: none;
        flex-direction: column;
        gap: 24px;
        padding: 40px 24px;
    }
    .autho-left-lk.pc {
        display: none;
    }
    .input-item:nth-child(4) {
        grid-area: 3 / 1 / 4 / 2;
    }
    .autho-left-lk.mob {
        display: block;
    }
    .autho-left h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 124%;
    }
    .police,
    .police a {
        font-size: 10px;
    }
    .btn-autho {
        margin: 0;
        margin-bottom: 9px;
        font-size: 12px;
    }
}
</style>
