<template>
    <section :class="this.$store.state.currentLang" v-if="data != null" id="business-model">
        <div class="container business-model-container">
            <h2 v-html="data?.title"></h2>
            <div class="model-text">
                <p v-html="data?.sub_title"></p>
                <span v-html="data?.text"></span>
            </div>
            <div class="business-model-list">
                <div class="business-model-item" v-for="item in data?.items">
                    <img :src="item.icon" alt="">
                    <span>{{ item.title }}</span>
                    <p v-html="item.text"></p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null,
    }
}
</script>
<style>

    .business-model-container h2 {
        font-weight: 600;
    }

    .model-text {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-top: 12px;
        margin-bottom: 58px;
    }

    .model-text p {
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 148%;
    }

    .model-text span {
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 164%;
    }

    .business-model-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
    }

    .business-model-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .business-model-item span {
        color: var(--blue);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 164%;
        text-transform: uppercase;
    }

    .business-model-item p {
        color: var(--dark_gray);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
        letter-spacing: 0;
    }

    .business-model-item img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
    }

    @media screen and (max-width: 1200px) {

        .business-model-container h2 {
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 116%;
        }

        .kg .business-model-container h2 {
            font-size: 30px;
        }

        .model-text {
            grid-template-columns: 1fr;
            gap: 0px;
            margin-bottom: 48px;
        }

        .business-model-list {
            grid-template-columns: 1fr;
        }

        .model-text p {
            display: block;
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 148%;
            margin-bottom: 12px;
        }

        .model-text span {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 148%;
            width: 100%;
        }

        .business-model-item img {
            margin-bottom: 20px;
        }

        .business-model-item {
            gap: 4px;
        }

        .business-model-list {
            gap: 40px;
        }

        .business-model-item span {
            font-size: 16px;
        }

        .business-model-item p {
            font-size: 13px;
        }
    }
</style>