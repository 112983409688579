<template>
    <Popup type="openRedirect"> 
        <template v-slot:header>
            <div class="popup-logo">
                <img :src="require('@/assets/img/popup.svg')" alt="" />
            </div>
            <h3 v-html="data?.title"></h3>
            <p class="redirect-subtitle" v-html="data?.subtitle"></p>
            <Button @click = "redirectComagic"
                :value="data?.btn_text"
                btn_class="white"
                :link="$store.state.popupLink"
            />
        </template>
    </Popup>
</template>
<script>
import { sendToComagic, sendComagic } from '@/assets/js/comagic.js'
import Popup from "../base/Popup.vue";
import Button from "./Button.vue";
import router from '@/router';

export default {
    components: {
        Popup,
        Button
    },
    props: {
        data: null
    },
    methods: {
        redirectComagic() {
            if (router.currentRoute.value.path == '/marketing-plan') {
                sendToComagic('', '', 'страница маркетинг-план переход на сайт ' + this.chekLink, '-')
            } else {
                sendToComagic('', '', 'Переход на сайт ' + this.chekLink, '-')
            }
        }
    },
    computed: {
        chekLink() {
            if (this.$store.state.popupLink == 'https://www.taomed.ru/') {
                return 'taomed.ru'
            } else {
                return ' taovita.ru'
            } 
        }
    }
}
</script>
<style>
    .redirect-subtitle {
        color: rgba(255, 255, 255, 0.72);
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 148%;
        letter-spacing: 0.28px;
    }

    .redirect-btn {
        color: var(--blue);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%;
        letter-spacing: 0.28px;
        text-transform: uppercase;
    }
</style>