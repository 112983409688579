<template>
    <section id="not-found" class="first">
        <div class="container">
            <h1> 
                <span>404</span>
                Страница не найдена
            </h1>
        </div>
    </section>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        methods: {
            ...mapMutations({
                setLoading: 'page/setLoading',
            }),
        },
        mounted() {
            setTimeout(() => {
                this.setLoading(false);
            }, 2000);
        }
    }
</script>

<style scoped>
    #not-found {
        margin-bottom: 200px;
        padding-top: 200px;
    }

    h1 {
        font-size: 8vh;
        line-height: 8vh;
    }

    h1 span {
        font-size: 20vh;
        line-height: 20vh;
        display: block;
    }
</style>