<template>
    <section :class="this.$store.state.currentLang" v-if="data != null" id="start-ws">
        <div class="container">
            <h2 v-html="data?.title"></h2>
            <div class="start-ws-card-list">
                <div class="start-ws-card-item" v-for="item, index in data?.cards">
                    <div class="start-ws-number">0{{ index + 1 }}</div>
                    <div class="start-ws-title" v-html="item?.title"></div>
                    <div class="start-ws-text" v-html="item?.text"></div>
                    <div class="start-ws-social" v-if="index == 0">
                        <a class="ws-ico" :href="data?.ws_link" target="_blank">
                            <img :src="require('@/assets/img/ws-ico.png')">
                            <div class="before" v-html="data?.ws_text"></div>
                        </a>
                        <a class="phone-ico" :href="data?.phone">
                            <img :src="require('@/assets/img/phone-ico.png')">
                            <div class="before" v-html="data?.phone_text"></div>
                        </a>
                        <a class="form-ico">
                            <img :src="require('@/assets/img/form-ico.png')" @click="popupOpen">
                            <div class="before" v-html="data?.form_text"></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null
    },
    methods: {
        popupOpen() {
        this.$store.commit('currentPlace', 'форма “С чего начать?”');
        this.$store.commit("openPopup", true);
        },
    },
}
</script>
<style>
    #start-ws h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 124%;
        margin: 0px auto;
        text-align: center;
        margin-bottom: 48px;
    }

    .start-ws-card-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        row-gap: 55px;
    }

    .start-ws-number {
        color: var(--sky);
        font-size: 36px;
        font-weight: 700;
        line-height: 96%;
        margin-bottom: 8px;
    }

    .start-ws-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 124%;
        margin-bottom: 10px;
    }

    .start-ws-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 156%;
    }

    .start-ws-social {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 12px;
    }

    .ws-ico,
    .phone-ico,
    .form-ico {
        position: relative;
        cursor: pointer;
        display: block;
        width: 48px;
        height: 48px;
    }

    .ws-ico .before {
        position: absolute;
        display: flex;
        width: max-content;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: #39AE41;
        padding: 6px 8px;
        font-size: 11px;
        font-weight: 600;
        line-height: 132%;
        color: var(--white);
        transition: 0.5s;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        top: -38px;
        left: 50%;
        z-index: 10;
    }

    .phone-ico .before {
        position: absolute;
        display: flex;
        width: max-content;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: #0A97A0;
        padding: 6px 8px;
        font-size: 11px;
        font-weight: 600;
        line-height: 132%;
        color: var(--white);
        transition: 0.5s;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        top: -38px;
        left: 50%;
        z-index: 10;
    }

    .form-ico .before {
        position: absolute;
        display: flex;
        width: max-content;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: #0A97A0;
        padding: 6px 8px;
        font-size: 11px;
        font-weight: 600;
        line-height: 132%;
        color: var(--white);
        transition: 0.5s;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        top: -38px;
        left: 50%;
        z-index: 10;
    }

    .ws-ico:hover .before,
    .phone-ico:hover .before,
    .form-ico:hover .before {
        opacity: 1;
        pointer-events: all;
    }

    @media screen and (max-width: 1200px) {
        .start-ws-card-list {
            grid-template-columns: 1fr;
            gap: 32px;
        }

        #start-ws h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 124%;
            text-align: left;
            margin-bottom: 24px;
        }

        .start-ws-number {
            font-size: 36px;
            font-weight: 700;
            line-height: 96%;
        }

        .start-ws-title {
            font-size: 18px;
            font-weight: 700;
            line-height: 124%;
            margin-bottom: 4px;

        }

        .start-ws-text {
            font-size: 13px;
        }

        .ws-ico::before,
        .phone-ico::before,
        .form-ico::before {
            display: none;
        }

        .start-ws-social {
            margin-top: 16px;
        }
    }
</style>