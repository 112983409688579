<template>
    <div class="popup-map" :class="this.$store.state.popupmap == true ? 'active' : ''">
        <div class="popup-bg" @click="popupClose()"></div>
        <div class="popup-map-container">    
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="close-popup-map" @click="popupClose()">
                <path opacity="0.72" d="M13.148 0L16 2.85205L10.9519 7.90018L15.943 12.8913L12.8913 15.943L7.90018 10.9519L2.85205 16L0 13.148L5.04813 8.09982L0.0570403 3.10873L3.10873 0.0570403L8.09982 5.04813L13.148 0Z" fill="#363636"/>
            </svg>
            <div class="title-popup-container">
                <h3 v-html="data?.popup_title"></h3>
                <div class="popup-map-text" v-html="data?.popup_addresses[active_index]?.address_text"></div>
            </div>
            <div class="map-container">
                <div class="map-btns">
                    <div class="map-btn-item" :class="index == active_index ? 'active' : ''" @click="setActive(item, index)" v-for="item, index in data?.popup_addresses" v-html="item?.address_title"></div>
                </div>
                <Map  :data="data?.popup_addresses" :active_marker="active_marker"/>
            </div>
        </div>
    </div>
</template>
<script>
import Map from '@/components/UI/Map.vue'
import store from '@/store'
    export default {
        components: {
            Map
        },
        props: {
            data: null
        },
        data() {
            return {
                active_index: -1,
                active_marker: null
            }
        },
        methods: {
            setActive(item, index) {
                if (index != this.active_index) {
                    this.active_index = index
                    this.active_marker = item
                }
            },
            popupClose() {
                this.$store.commit("setPopupMap", false);
                document.querySelector('body').style.overflow = "";
            }
        },
    }
</script>
<style>
    .popup-map {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100lvh;
        opacity: 0;
        pointer-events: none;
        z-index: 1000;
        transition: 0.8s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .popup-map.active {
        opacity: 1;
        pointer-events: all;
    }

    .title-popup-container h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 112%;
    }

    .popup-map-container {
        position: relative;
        background-color: var(--white);
        height: auto;
        z-index: 100;
        padding: 50px 45px;
        border-radius: 32px;
        width: 1200px;
    }

    .close-popup-map {
        position: absolute;
        top: 44px;
        right: 44px;
        cursor: pointer;
        z-index: 100;
    }

    .title-popup-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-end;
    }

    .map-btns {
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 4px;
        transform: translateY(calc(100% + 16px));
        z-index: 10;
        padding-left: 18px;
    }
    
    .map-btn-item {
        background: var(--white);
        font-size: 12px;
        font-weight: 700;
        line-height: 108%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        border-radius: 12px;
        transition: 0.5s;
        border: 1px solid var(--white);
        cursor: pointer;

    }

    .map-btn-item:hover {
        border: 1px solid var(--blue);
        color: var(--blue);
    }

    .map-btn-item.active {
        background: var(--sky);
        border: 1px solid var(--blue);
        color: var(--blue);
    }

    .popup-map-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 156%;
    }

    .map-container {
        height: 312px;
        position: relative;
    }

    @media screen and (max-width: 1200px) {
        .popup-map-container {
            width: 328px;
            height: 528px;
            padding: 24px;
            border-radius: 32px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        .map-container {
            position: relative;
            width: 100%;
        }

        .title-popup-container {
            grid-template-columns: 1fr;
        }

        .map-btns {
            width: 100%;
            padding-left: 12px;
            flex-wrap: wrap;
            transform: translateY(calc(100% + -30px));
        }

        .title-popup-container h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 112%;
            margin-bottom: 8px;
        }

        .popup-map-text {
            font-size: 13px;
            font-weight: 500;
            line-height: 156%;
        }

        .map-container {
            height: 337px;
        }

        .close-popup-map {
            top: 32px;
            right: 28px;
        }
    }
</style>