<template>
  <section :class="this.$store.state.currentLang">
    <div class="container helping-container">
      <div class="swiper-container">
        <div class="swiper" ref="slider">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="img in data?.imgs">
              <img :src="img" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper-pagination helping-pagination"></div>
      </div>
      <div class="helping-text">
        <h2 class="helping-title" v-html="data?.title"></h2>
        <div v-html="data?.text"></div>
        <Button
          @click="popupOpen"
          :value="data?.btn_text"
          btn_class="blue"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper";
import Button from "@/components/UI/Button.vue";
import "swiper/css";

export default {
  components: {
    Button,
  },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'форма “Мы помогаем партнерам строить бизнес с 2022 года”');
      this.$store.commit("openPopup", true);
    },
  },
  props: {
    data: null,
  },
  data() {
    return {
      slider: null,
    };
  },
  watch: {
    data(new_value) {
      if (new_value?.imgs.length > 0) {
        if (!this.slider) {
          this.slider = new Swiper(this.$refs.slider, {
            slidesPerView: 1,
            mousewheel: true,
            spaceBetween: 40,
            autoplay: {
              delay: 3000,
            },
            pagination: {
              el: ".swiper-pagination.helping-pagination",
              clickable: true,
            },
          });
        } else {
          this.slider.update();
        }
      }
    },
  },
};
</script>
<style>
.helping-title {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%;
  margin-bottom: 24px;
}

.helping-text p {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
  margin-bottom: 64px;
}

.helping-text span {
  display: block;
  color: var(--blue);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 164%;
  margin-bottom: 32px;
}

.helping-text .button {
  padding: 24px 36px;
}

.helping-text .btn {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  padding: 24px 36px;
  border-radius: 24px;
  background: #0a97a0;
  width: max-content;
  cursor: pointer;
  transition: 0.5s;
}

.helping-text .btn:hover {
  background: var(--light_blue);
}

.helping-container {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.helping-container .swiper {
  width: 100%;
  max-width: 550px;
}

.helping-container .helping-text {
  width: 100%;
}

.helping-container img {
  border-radius: 64px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.swiper {
  position: relative;
}

.helping-container .swiper-pagination .swiper-pagination-bullet {
  display: block;
  width: 24px;
  height: 8px;
  border-radius: 8px;
  background: var(--light_gray);
  transition: 0.5s;
}

.helping-container
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  display: block;
  width: 64px;
  height: 8px;
  background: var(--blue);
}

.helping-pagination {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
}

.helping-container .swiper-container {
  flex-direction: column;
}

.kg .helping-text .button {
  text-align: left;
}

.uz_cyr .helping-title {
  color: #242424;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%;
}

.uz_cyr .helping-text span {
  color: #0A97A0;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 164%;
}

@media screen and (max-width: 1200px) {
  .kz .helping-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    letter-spacing: 0;
  }
  
  .kz .helping-text .button.blue {
    font-size: 12px;
  }

  .helping-container {
    flex-direction: column;
    gap: 36px;
  }

  .helping-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    margin-bottom: 24px;
  }

  .helping-text p {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
    margin-bottom: 24px;
  }

  .helping-text span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
    margin-bottom: 48px;
  }

  .helping-text .btn {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    padding: 16px 24px;
  }

  .helping-container img {
    border-radius: 48px;
  }

  .helping-pagination {
    margin-top: 12px;
  }

  .helping-container .swiper-pagination .swiper-pagination-bullet {
    height: 4px;
  }

  .helping-container
    .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 4px;
  }

  .helping-text .button.blue {
    width: 100%;
    font-size: 14px;
    padding: 16px 24px;
  }

  .uz_cyr .helping-title {
    color: #242424;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%; /* 23.76px */
  }

  .uz_cyr .helping-text span {
    color: #0A97A0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
  }
}
</style>
