<template>
  <section id="clinica" :class="this.$store.state.currentLang" v-if="data != null">
    <div class="container manufacturing-company__wrapper">
      <h2 class="manufacturing-company-title" v-html="data?.title"></h2>
      <div class="manufacturing-company-imgs">
        <div class="manufacturing-company-img large">
          <img :src="data?.large_img" alt="" />
        </div>
        <div class="manufacturing-company-img">
          <img :src="data?.img" alt="" />
        </div>
      </div>
      <div class="manufacturing-company-texts">
        <div class="manufacturing-company-text" v-html="data?.bold_text"></div>
        <div class="manufacturing-company-right">
          <div class="manufacturing-company-text large" v-html="data?.small_text"></div>
          <div class="clinica-btn" @click="openRedirect">
            {{ data?.clinica_btn }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.4853 1.75736C11.4853 1.20508 11.0376 0.757364 10.4853 0.757364L1.48528 0.757364C0.932997 0.757364 0.485281 1.20508 0.485281 1.75736C0.485281 2.30965 0.932997 2.75736 1.48528 2.75736L9.48528 2.75736L9.48528 10.7574C9.48528 11.3096 9.933 11.7574 10.4853 11.7574C11.0376 11.7574 11.4853 11.3096 11.4853 10.7574L11.4853 1.75736ZM2.70711 10.9498L11.1924 2.46447L9.77817 1.05026L1.29289 9.53554L2.70711 10.9498Z" fill="#fdf5e6"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: null,
  },
  methods: {
    openRedirect() {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", this.data?.clinica_btn_link);
    },
  },
};
</script>
<style>
.manufacturing-company__wrapper {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.manufacturing-company-title {
  color: var(--blue);
}
.manufacturing-company-imgs {
  display: flex;
  gap: 20px;
}
.manufacturing-company-img {
  width: calc(50% - 20px);
}
.manufacturing-company-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 48px;
  border: 1px solid rgba(0, 0, 0, 0.24);
}
.manufacturing-company-img.large {
  width: 100%;
}
.manufacturing-company-texts {
  margin-top: 14px;
  display: flex;
  gap: 67px;
}
.manufacturing-company-text {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 700;
  line-height: 148%; /* 41.44px */
}
.manufacturing-company-text.large {
  font-size: 20px;
  font-weight: 500;
  line-height: 164%; /* 32.8px */
}

.uz_cyr .manufacturing-company-text {
  color: #242424;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%;
}

.uz_cyr .manufacturing-company-text.large {
  color: #242424;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
}

.uz_cyr .manufacturing-company-texts {
  gap: 48px;
}

.manufacturing-company-right {
  display: flex;
  flex-direction: column;
}

.clinica-btn {
  cursor: pointer;
  width: max-content;
  margin-top: 24px;
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 132%;
  background: var(--blue);
  color: var(--biege);
  gap: 8px;
  text-transform: uppercase;
  transition: 0.5s;
}

.clinica-btn:hover {
  background: rgba(10, 151, 160, 0.84);
}

@media screen and (max-width: 1200px) {

  .manufacturing-company-title {
    font-size: 22px;
  }

  .manufacturing-company-texts {
    flex-direction: column;
    margin-top: -4px;
    gap: 12px;
  }
  .manufacturing-company-imgs {
    margin-left: -20px;
    width: calc(100% + 40px);
    flex-direction: column;
    gap: 12px;
  }
  .manufacturing-company-img,
  .manufacturing-company-img.large {
    width: 100%;
  }
  .manufacturing-company-img.large,
  .manufacturing-company-img.large img {
    max-height: 160px;
  }
  .manufacturing-company-img,
  .manufacturing-company-img img {
    height: 330px;
    max-height: 330px;
  }
  .manufacturing-company-text {
    font-size: 18px;
    line-height: 132%; /* 23.76px */
  }
  .manufacturing-company-text.large {
    color: var(--dark_gray);
    font-size: 13px;
    font-weight: 500;
    line-height: 164%; /* 21.32px */
  }
  .manufacturing-company__wrapper {
    gap: 32px;
  }

  .uz_cyr .manufacturing-company-text {
    color: #242424;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
  }
  .uz_cyr .manufacturing-company-text.large {
    color: #121212;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
  }
}
</style>
