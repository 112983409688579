<template>
    <section  :class="this.$store.state.currentLang" id="performance-section" v-if="data != null">
        <div class="container performance-cointainer">
            <span class="performance">{{ data?.top_title }}</span>
            <div class="performance-text">
                <h2 v-html="data?.title"></h2>
                <p v-html="data?.text"></p>
            </div>
            <div class="performance-img">
                <img :src="img" alt="" v-for="img in data?.imgs">
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null,
    }
}
</script>
<style>
    .uz_cyr .performance-text h2 {
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
    }

    .uz_cyr .performance-text p {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
    }

    .performance-cointainer {
        margin-bottom: 180px;
    }

    .performance {
        display: block;
        color: var(--blue);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 124%;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        margin-bottom: 14px;
    }

    .performance-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        margin-bottom: 48px;
    }
    
    .performance-text h2 {
        color: var(--gray);
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%;
    }

    .performance-text p {
        color: var(--gray);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
        letter-spacing: 0.32px;
        max-width: 372px;
    }

    .performance-img {
        display: flex;
        flex-direction: row;
        gap: 40px;
    }

    .performance-img img {
        width: 100%;
        height: auto;
        border-radius: 48px;
    }

    .uz .performance-text h2 {
        color: #242424;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%;
    }

    .uz .performance-text p {
        color: #242424;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
    }

    .uz .performance-text {
        gap: 25px;
    }

    .kz .performance-text h2 {
        font-size: 39px;
    }

    .kz .performance-text p {
        font-size: 15px;
        letter-spacing: 0;
    }

    @media screen and (max-width: 1300px) {
        .performance-img {
            gap: 20px;
        }
    }

    @media screen and (max-width: 1200px) {
        .kz .performance-text h2 {
            font-size: 22px;
        }

        .kz .performance-text p {
            font-size: 13px;
            letter-spacing: 0;
        }

        #performance-section {
            margin-bottom: 124px;
        }

        .performance-text {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 24px;
            margin-bottom: 24px;
        }

        .performance-text h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .performance-img img {
            display: none;
        }

        .performance-img img:first-of-type {
            display: block;
            max-width: 400px;
            margin: 0px auto;
            border-radius: 32px;
        }

        .performance-text p {
            max-width: 100%;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
        }

        .performance {
            display: block;
            margin-bottom: 8px;
            font-size: 14px;
        }

        .uz .performance-text h2 {
            color: #242424;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .uz .performance-text p {
            color: #484848;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
        }

        .uz_cyr .performance-text h2 {
            color: #242424;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .uz_cyr .performance-text p {
            color: #484848;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
            letter-spacing: 0;
        }
    }
</style>