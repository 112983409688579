<template>
  <section v-if="data != null">
    <div class="container pluses__wrapper">
      <div class="pluses-content">
        <div class="pluses-header">
          <h2 class="pluses-title" v-html="data?.title"></h2>
          <div class="pluses-subtitle" v-html="data?.sub_title"></div>
        </div>
        <ol class="pluses-list">
          <li class="pluses-item" v-for="item in data?.items" v-html="item.text"></li>
        </ol>
      </div>
      <div class="pluses-icon">
        <img :src="require('@/assets/img/pluses.png')" alt="" />
      </div>
      <Button
        class="pluses-btn"
        :value="'Подробнее'"
        :btn_class="'blue-stroke'"
        :link="data?.link"
      />
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
export default {
  components: {
    Button,
  },
  props: {
    data: null,
  }
};
</script>
<style>
.pluses__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 70px;
}
.pluses-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.pluses-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.pluses-title {
  font-size: 32px;
}
.pluses-subtitle {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 172%; /* 27.52px */
}
.pluses-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.pluses-item {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 164%; /* 22.96px */
}
.pluses-icon {
  margin-top: -80px;
  pointer-events: none;
  overflow: hidden;
}
.pluses-icon img {
  rotate: 45deg;
}

.pluses-btn {
  display: none;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .pluses__wrapper {
    flex-direction: column;
    gap: 53px;
  }
  .pluses-header {
    gap: 8px;
  }
  .pluses-content {
    gap: 32px;
  }
  .pluses-title {
    font-size: 18px;
  }
  .pluses-subtitle {
    color: var(--blue);
    font-size: 14px;
    line-height: 148%; /* 20.72px */
  }
  .pluses-item {
    font-size: 13px;
    font-weight: 500;
    line-height: 164%; /* 21.32px */
  }
  .pluses-icon {
    background-color: var(--biege);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .pluses-icon img {
    width: 50%;
    padding-bottom: 15px;
  }
  .pluses-btn {
    display: block;
  }
  .pluses-list {
    width: 100%;
  }
}
</style>
