<template>
    <section id="started-section" v-if="data != null">
        <div class="container own-container">
            <span class="top-span">{{ data?.top_title }}</span>
            <h2 v-html="data?.title"></h2>
            <div class="own-list">
                <div class="own-item" v-for="(step, index) in data?.steps" :class="(index + 1) % 2 == 0 ? 'reverse' : ''">
                    <img :src="step.img" alt="">
                    <div class="started-item-text desktop" v-html="step.text">
                    </div>
                    <div class="started-item-text mobile" v-html="step.mobile_text">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null,
    }
}
</script>
<style>
    .own-container h2 {
        margin-bottom: 48px;
    }

    .own-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        margin-bottom: 48px;
    }

    .own-item.reverse {
        flex-direction: row-reverse;
        margin-bottom: 0px;
    }

    .own-item img {
        border-radius: 48px;
        width: 100%;
        max-width: 580px;
    }

    .started-item-text {
        display: flex;
        flex-direction: column;
        /* gap: 16px; */
    }

    /* .started-item-text p {
        color: var(--gray);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
        letter-spacing: 0.28px;
    } */

    .started-item-text p strong {
        color: var(--blue);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 164%;
        letter-spacing: 0.28px;
    }

    .started-item-text h3 {
        margin-bottom: 16px;
    }

    @media screen and (max-width: 1200px) {

        #started-section {
            margin-bottom: 116px;
        }

        #started-section .own-item {
            margin-bottom: 84px;
        }

        .own-item {
            flex-direction: column;
            gap: 24px;
        }

        .own-item.reverse {
            flex-direction: column;
            gap: 24px;
        }

        .own-item {
            margin-bottom: 104px;
        }

        .own-item.reverse {
            margin-bottom: 0px;
        }

        .own-item img {
            width: 100%;
            max-width: 100%;
            height: auto;
            border-radius: 32px;
        }

        .started-item-text {
            /* gap: 8px; */
        }

        .started-item-text h3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
            letter-spacing: 0.32px;
            margin-bottom: 8px;
        }

        .own-item-text p,
        .own-item-text ol,
        .own-item-text li {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
        }

        .own-container h2 {
            margin-bottom: 36px;
            font-size: 24px;
        }

        .uz .own-container h2 {
            color: #242424;
            font-family: Montserrat;
            font-size: 23px;
            font-style: normal;
            font-weight: 700;
            line-height: 132%;
        }

        .uz_cyr .own-container h2 {
            font-size: 22px;
        }

        .started-item-text strong {
            font-size: 12px;
        }

        .started-item-text p {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
            letter-spacing: 0;
        }

        .own-item-text {
            gap: 0px;
            width: 100%;
        }

        .own-item-text h3 {
            margin-bottom: 8px;
        }

        .own-item-text ol {
            padding-left: 17px;
        }

        .kg .own-item-text p,
        .kg .own-item-text ol,
        .kg .own-item-text li {
            font-size: 11px;
        }
    }
</style>