<template>
  <section :class="this.$store.state.currentLang" class="coronavirus-section" v-if="data != null">
    <div class="container coronavirus__wrapper">
      <div class="coronavirus-img">
        <img :src="data?.img" alt="" />
      </div>
      <div class="coronavirus-content">
        <div class="coronavirus-header">
          <div class="coronavirus-subtitle" v-html="data?.year"></div>
          <h2 class="coronavirus-title" v-html="data?.title"></h2>
        </div>
        <div class="coronavirus-texts">
          <div class="coronavirus-text" v-html="data?.text1"></div>
          <div class="coronavirus-text" v-html="data?.text2"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    props: {
        data: null,
    },
  }
</script>
<style>
.coronavirus-section {
  background-color: var(--biege);
  border-radius: 96px;
  padding: 0;
}
.container.coronavirus__wrapper {
  position: relative;
  padding: 95px 20px 95px 204px;
}
.coronavirus-img {
  position: absolute;
  top: 95px;
  left: 0;
  width: 180px;
  height: 180px;
  aspect-ratio: 1 / 1;
}
.coronavirus-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.coronavirus-content {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.coronavirus-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.coronavirus-subtitle {
  color: var(--blue);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 124%; /* 19.84px */
  letter-spacing: 0.32px;
  text-transform: uppercase;
}
.coronavirus-title {
  z-index: 1;
}
.coronavirus-texts {
  display: flex;
  gap: 40px;
}
.coronavirus-text {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 164%; /* 22.96px */
  letter-spacing: 0.28px;
  width: 60%;
}

.coronavirus-text:last-of-type {
  width: 40%;
}
.coronavirus-text span {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 800;
  line-height: 164%;
  letter-spacing: 0.28px;
}
.coronavirus-text p,
.coronavirus-text span {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  #coronavirus-section {
    margin-bottom: 158px;
  }
  .coronavirus-section {
    border-radius: 32px;
  }
  .container.coronavirus__wrapper {
    padding: 50px 20px;
  }
  .container.coronavirus__wrapper,
  .coronavirus-texts {
    flex-direction: column;
  }
  .coronavirus-texts {
    gap: 36px;
  }
  .coronavirus-text {
    width: 100%;
  }
  .coronavirus-text p,
  .coronavirus-text span {
    color: #484848;
    font-size: 12px;
    font-weight: 500;
  }
  .coronavirus-content {
    margin: 0;
    gap: 26px;
  }
  .coronavirus-title {
    font-size: 24px;
  }
  .coronavirus-img {
    width: 135px;
    height: 135px;
    left: unset;
    right: 20px;
    top: 50px;
  }
  .coronavirus-subtitle {
    font-size: 14px;
  }
  .coronavirus-header {
    gap: 8px;
  }

  .coronavirus-text {
    width: 100%;
    letter-spacing: 0
  }

  .coronavirus-text:last-of-type {
    width: 100%;
  }

  .kg .coronavirus-text p,
  .kg .coronavirus-text span {
    font-size: 11px;
  }
}
</style>
