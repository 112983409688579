import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import NotFound from '@/views/404View.vue'
import Page from '@/views/PageView.vue'
import Components from '@/views/ComponentsView.vue'
import RegisterView from '@/views/RegisterView.vue'
import AuthorizationView from '@/views/AuthorizationView.vue'
import Policy from '@/views/Policy.vue'
import User from '@/views/User.vue'
import DetailViews from '@/views/DetailViews.vue'
import PlanView from '@/views/PlanView.vue'


const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/reg',
        name: 'registration',
        component: RegisterView
    },
    {
        path: '/autho',
        name: 'authorization',
        component: AuthorizationView
    },
    {
        path: '/marketing-plan',
        name: 'plan',
        component: PlanView
    },
    {
        path: '/product/:id',
        name: 'product',
        component: DetailViews
    },
    {
        path: '/privacy-policy/:id',
        name: 'Policy',
        component: Policy
    },
    {
       path: '/user/:id/',
        name: 'User',
        component: User
    },
    {
        path: '/404',
        component: NotFound
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

export default router
