<template>
  <div id="map2"></div>
</template>
  
<script setup>
import store from "@/store";
import { ref, reactive, onUpdated, onMounted, watch } from "vue";

const props = defineProps(['data', 'active_marker'])
let map


function init() {
  const map_container = document.querySelector("#map2");
  if (map_container) {
    map_container.innerHTML = "";
  }
  map = new ymaps.Map(map_container, {
    center: [56.01, 92.85],
    zoom: window.innerWidth > 1200 ? 3 : 2,
    controls: ["zoomControl", "rulerControl"],
  });
  map.behaviors.disable("scrollZoom");
  const locations = props.data;

  if (!locations) {
    return
  }

  if (locations.length > 0) {
    locations.forEach((item) => {
      const placemark = new ymaps.Placemark(
        [item?.address_coord?.y, item?.address_coord?.x],
        { iconCaption: item?.address_title },
        { preset: "islands#circleDotIcon" }
      );
      map.geoObjects.add(placemark);
    });
  }

  // store.commit("setMap", map);
}
onUpdated(() => {
  const coords = [props.active_marker?.address_coord.y, props.active_marker?.address_coord.x]
  if (map) {
    map.setZoom('14');
    map.setCenter(coords)
  }
});

watch(()=> store.state.popupmap, (newValue, oldValue) => {
  if (newValue == true) {
    if (map) {
      init();
    }
  }
});

document.addEventListener("DOMContentLoaded", () => {
  let attempts = 0;
  const maxAttempts = 20;
  const interval = setInterval(() => {
    if (ymaps && document.querySelector("#map2") || attempts >= maxAttempts) {
      setTimeout(() => {
        ymaps.ready(init);
      }, 1000);
      clearInterval(interval);
    }
    attempts++;
  }, 300);
});
</script>
<style>
#map2 {
  margin: 0;
  margin-top: 10px;
  width: 100%;
  height: 508px;
  border-radius: 24px;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  #map2 {
    width: calc(100%);
    border-radius: 16px;
    overflow: hidden;
    margin-top: -40px;
  }
}
</style>
  