<template>
  <Popup type="successPopup">
    <template v-slot:header>
      <div class="popup-logo">
        <img :src="require('@/assets/img/popup.svg')" alt="" />
      </div>
      <h3 v-html="data?.success_title"></h3>
      <p class="success-subtitle" v-html="data?.success_title_text"></p>
    </template>
  </Popup>
</template>
<script>
import Popup from "../base/Popup.vue";
export default {
  components: { Popup },
  props: {
    data: null
  }
};
</script>
<style>
.success-subtitle {
  color: rgba(255, 255, 255, 0.72);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 148%; /* 20.72px */
  letter-spacing: 0.28px;
}
</style>
