import RegistrationApi from "@/assets/js/api/registration/index.js";

class RegApi extends RegistrationApi {
  async reg(method, firstname, email, passwd, mobile) {
    try {
      const data = {
        method,
        firstname,
        email,
        passwd,
        mobile,
      };

      const result = await this.registration("methods/taovita", data, "post");

      return result?.data;
    } catch (error) {
      router.push("/404");
      console.error(error);
      return false;
    }
  }
}

export default new RegApi();
