<template>
  <section v-if="data != null">
    <div class="container medicine__wrapper">
      <div class="medicine__info">
        <div class="medicine__info-header">
          <h2 class="medicine__info-title" v-html="data?.title"></h2>
          <div class="medicine__info-subtitle" v-html="data?.subtitle">
          </div>
        </div>
        <div class="medicine-hint" v-html="data?.hint"></div>
      </div>
      <div class="medicine__pictures">
        <div class="medicine__pictures-main">
          <img :src="data?.img1" alt="" />
        </div>
        <div class="medicine__pictures-others">
          <img :src="data?.img2" alt="" />
          <img :src="data?.img3" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    props: {
      data: null
    }
  }
</script>
<style>
.medicine__wrapper {
  display: flex;
  gap: 46px;
}
.medicine__info {
  display: flex;
  flex-direction: column;
  gap: 56px;
  /* justify-content: space-between; */
  width: 100%;
}
.medicine__info-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.medicine__info-title {
  line-height: 124%;
  font-size: 42px;
}
.medicine__info-subtitle {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 172%; /* 27.52px */
}
.medicine-hint {
  width: 85%;
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 164%; /* 26.24px */
  padding: 24px 38px 29px 28px;
  background-color: var(--biege);
  border-radius: 32px;
}
.medicine__pictures {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 80%;
}
.medicine__pictures-main {
  width: 100%;
  height: 250px;
}
.medicine__pictures-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 48px;
}
.medicine__pictures-others {
  width: 100%;
  height: 250px;
  display: flex;
  gap: 16px;
}
.medicine__pictures-others img {
  width: calc(100% / 2 - 8px);
  height: 100%;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 48px;
}

@media screen and (max-width: 1200px) {
  .medicine__wrapper {
    flex-direction: column-reverse;
    gap: 0px;
  }
  .medicine__pictures {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-bottom: 42px;
  }
  .medicine__pictures,
  .medicine__pictures-others {
    gap: 10px;
  }
  .medicine__pictures-main,
  .medicine__pictures-others {
    height: 177px;
  }
  .medicine__pictures-main img,
  .medicine__pictures-others img {
    border-radius: 24px;
  }
  .medicine__pictures-others img {
    width: calc(100% / 2 - 5px);
  }
  .medicine__info {
    gap: 36px;
  }
  .medicine__info-subtitle {
    width: 100%;
    font-size: 13px;
    line-height: 164%; /* 21.32px */
  }
  .medicine-hint {
    margin-left: -20px;
    width: calc(100% + 40px);
    padding: 24px 40px 30px 28px;
    font-size: 14px;
  }

  .medicine__info-title {
    font-size: 20px;
  }
}
</style>
