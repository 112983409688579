<!-- <template>
  <div
    :class="'mobile-wrapper ' + ($store.state.mobileMenuOpen ? 'active' : '')"
  >
    <div class="mobile-wrapper-bg" @click="closeMenu"></div>
    <div class="container">
      <div class="mobile-header">
        <img src="@/assets/img/mobile_menu_logo.png" alt="" />
        <div
          :class="
            'header_burger ' + ($store.state.mobileMenuClose ? 'active' : '')
          "
          @click="closeMenu"
        >
          <span class="burger_top"></span>
          <span class="burger_middle"></span>
          <span class="burger_bottom"></span>
        </div>
      </div>
      <div class="mobile-menu">
        <ul class="mobile-menu-list nav-menu-mobile">
          <li @click="closeMenu" v-for="(item, index) in data?.menu"><a :href="item?.link">{{ item?.title }}</a></li>
        </ul>
        <div class="question-list">
          <a @click="drop" href="/#friends" class="question-item">Преимущества Таовита</a>
          <a @click="drop" href="/#partner-reviews-section" class="question-item">Отзывы партнеров</a>
          <a @click="drop" href="/#business-model" class="question-item">Что предлагаем партнерам</a>
          <a @click="drop" href="/#start-ws" class="question-item">С чего начать?</a>
            <a @click="drop" href="https://wa.me/79911363717" target="_blank" class="question-item">Консультация в Whatsapp</a>
        </div>
        <a href="/autho" class="avtoriz-mobile">
          <span></span>
          {{ data?.btn_text }}
          <img src="@/assets/img/mobile-key.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    language: null,
    data: null,
  },
  methods: {
    closeMenu() {
      this.$store.commit("openMenu", false);
      this.$store.commit("closeMenu", false);
    },
    activeLanguage(tag, index) {
      this.closeMenu();
      this.active = index;
      this.$store.commit('setCurrentLang', tag);
      this.$emit("update");
    },
    drop() {
      this.$store.commit("openMenu", false);
    }
  }, 
  emit: ['update'],
};
</script>
<style>
.mobile-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100lvh;
  z-index: 110;
  pointer-events: none;
}

.mobile-wrapper-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.8s;
  pointer-events: none;
}

.mobile-wrapper .container {
  transform: translateY(-100%);
  display: none;
  position: relative;
  overflow: auto;
  transition: 0.5s;
  height: auto;
  border-bottom-right-radius: 50% 1em;
  border-bottom-left-radius: 50% 1em;
  background-image: radial-gradient(
    ellipse,
    var(--blue) 70.5%,
    transparent 71%
  );
  background-size: 300% 580px;
  background-position: 50% -105px;
  background-repeat: no-repeat;
  z-index: 20;

}

.mobile-menu-list.nav-menu-mobile {
  grid-column: 1 / span 2;
  grid-row: 1;
  columns: 2;
  display: block;
}

.mobile-menu-list li.active {
  text-decoration: underline #fff;
}

body.admin-bar .mobile-wrapper {
  top: 112px;
}

.mobile-wrapper.active {
  pointer-events: all;
}

.mobile-wrapper.active .mobile-wrapper-bg {
  opacity: 1;
  pointer-events: all;
}

.mobile-wrapper.active .container {
  transform: translate(0);
}
.mobile-wrapper .container {
  padding: 20px 16px 50px;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 16px;
}

.mobile-wrapper .header_burger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 24px;
  min-height: 16px;
  position: relative;
}

.mobile-wrapper .header_burger.active {
  transition: 0.8s;
}

.mobile-wrapper .header_burger span {
  position: absolute;
  height: 2px;
  width: 24px;
  background-color: var(--white);
}

.burger_top {
  transform: translateY(-7px);
  transition: 0.8s;
}

.mobile-wrapper .header_burger.active > .burger_top {
  transform: rotate(44deg);
  top: auto;
}

.burger_middle {
  transition: 0.8s;
}

.mobile-wrapper .header_burger.active > .burger_middle {
  transform: rotate(-45deg);
}

.burger_bottom {
  transform: translateY(7px);
  transform-origin: right;
  transition: 0.5s;
}

.mobile-wrapper .header_burger.active > .burger_bottom {
  opacity: 0;
  transform: scaleX(0.1) translateY(7px);
}

.mobile-menu-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding: 0;
}
.mobile-menu-list li > * {
  color: var(--white);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%; /* 14.04px */
  letter-spacing: 0.26px;
}

.avtoriz-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  padding-left: 44px;
  max-width: max-content;
  height: max-content;
  cursor: pointer;
  gap: 29px;
  background-color: var(--white);
  color: var(--blue);
  border-radius: 40px;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 108%;
  letter-spacing: -0.52px;
  align-self: flex-end;
}

.nav-menu-mobile li {
  margin-bottom: 12px;
}

@media screen and (max-width: 1200px) {
  .mobile-wrapper .container {
    display: block;
  }
 .mobile-wrapper.active {
    display: block;
  }
  .mobile-menu {
    grid-template-columns: 1fr;
    margin-top: 36px;
    gap: 32px;
  }
  .mobile-menu-list.nav-menu-mobile {
    grid-column: 1;
  }
  .question-list a {
    width: max-content;
  }
  .mobile-menu .question-list {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 3px;
    margin-top: 0px;
  }
  .mobile-menu .question-list a {
    font-size: 11px;
    font-weight: 600;
    line-height: 112%;
    padding: 8px;
    border-radius: 12px;
  }

  .avtoriz-mobile {
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    margin-top: 4px;
  }
}
</style> -->

<!-- Старое меню -->

<template>
  <div
    :class="'mobile-wrapper ' + ($store.state.mobileMenuOpen ? 'active' : 'close')"
  >
    <div class="mobile-wrapper-bg" @click="closeMenu"></div>
    <div class="container">
      <div :class="'mobile-header ' + ($store.state.mobileMenuOpen ? 'active' : '') ">
        <img class="mobile-header-logo" src="@/assets/img/mobile_menu_logo.png" alt="" />
        <div
          :class="
            'header_burger ' + ($store.state.mobileMenuClose ? 'active' : '')
          "
          @click="closeMenu"
        >
          <span class="burger_top"></span>
          <span class="burger_middle"></span>
          <span class="burger_bottom"></span>
        </div>
      </div>
      <div :class="'mobile-menu '+ ($store.state.mobileMenuOpen ? 'active' : '')">
        <ul class="mobile-menu-list nav-menu-mobile">
          <li @click="closeMenu" v-for="(item, index) in data?.menu"><a :href="item?.link">{{ item?.title }}</a></li>
        </ul>
        <ul class="mobile-menu-list">
          <li v-for="item, index in language" ref="language" :class="item?.tag == this.$store.state.currentLang ? 'active' : ''" @click="activeLanguage(item?.tag, index)" ><a :href="item?.link">{{ item?.text }}</a></li>
        </ul>
        <a @click="openRedirect(data?.btn_link)" class="avtoriz-mobile">
          {{ data?.btn_text }}
          <img src="@/assets/img/mobile-key.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    language: null,
    data: null,
  },
  methods: {
    closeMenu() {
      this.$store.commit("openMenu", false);
      this.$store.commit("closeMenu", false);
    },
    activeLanguage(tag, index) {
      this.closeMenu();
      this.active = index;
      this.$store.commit('setCurrentLang', tag);
      this.$emit("update");
    },
    openRedirect(link) {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", link);
    },
  }, 
  emit: ['update'],
};
</script>
<style>
.mobile-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100lvh;
  z-index: 110;
  pointer-events: none;
}

.mobile-wrapper-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.8s;
  pointer-events: none;
}

.mobile-wrapper .container {
  transform: translateY(-100%);
  display: none;
  position: relative;
  overflow: auto;
  transition: 0.5s;
  height: auto;
  border-bottom-right-radius: 50% 1em;
  border-bottom-left-radius: 50% 1em;
  background-image: radial-gradient(
    ellipse,
    var(--blue) 70.5%,
    transparent 71%
  );
  background-size: 300% 580px;
  background-position: 50% -105px;
  background-repeat: no-repeat;
  z-index: 20;
}

.mobile-menu-list.nav-menu-mobile {
  grid-column: 1 / span 2;
  grid-row: 1;
  columns: 2;
  display: block;
}

.mobile-menu-list li.active {
  text-decoration: underline #fff;
}

body.admin-bar .mobile-wrapper {
  top: 112px;
}

.mobile-wrapper.active {
  pointer-events: all;
}

.mobile-wrapper.active .mobile-wrapper-bg {
  opacity: 1;
  pointer-events: all;
}

.mobile-wrapper.active .container {
  transform: translate(0);
}
.mobile-wrapper .container {
  padding: 20px 16px 50px;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 16px;
}

.mobile-wrapper .header_burger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 24px;
  min-height: 16px;
  position: relative;
}

.mobile-wrapper .header_burger.active {
  transition: 0.8s;
}

.mobile-wrapper .header_burger span {
  position: absolute;
  height: 2px;
  width: 24px;
  background-color: var(--white);
}

.burger_top {
  transform: translateY(-7px);
  transition: 0.8s;
}

.mobile-wrapper .header_burger.active > .burger_top {
  transform: rotate(44deg);
  top: auto;
}

.burger_middle {
  transition: 0.8s;
}

.mobile-wrapper .header_burger.active > .burger_middle {
  transform: rotate(-45deg);
}

.burger_bottom {
  transform: translateY(7px);
  transform-origin: right;
  transition: 0.5s;
}

.mobile-wrapper .header_burger.active > .burger_bottom {
  opacity: 0;
  transform: scaleX(0.1) translateY(7px);
}

.mobile-menu-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding: 0;
}
.mobile-menu-list li > * {
  color: var(--white);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.26px;
}

.avtoriz-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  padding-left: 44px;
  max-width: max-content;
  height: max-content;
  cursor: pointer;
  gap: 29px;
  background-color: var(--white);
  color: var(--blue);
  border-radius: 40px;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 108%;
  letter-spacing: -0.52px;
  align-self: flex-end;
}

.nav-menu-mobile li {
  margin-bottom: 12px;
}

.mobile-wrapper.active .avtoriz-mobile {
  animation: animation_btn 1s;
  animation-iteration-count: inherit;
}

.mobile-menu-list li {
  overflow: hidden;
}

.mobile-wrapper.close .mobile-menu-list li {
  overflow: visible;
}

.mobile-menu-list li a {
  display: block;
}

.mobile-wrapper.active .mobile-menu-list li a {
  animation: animation_text 1s;
  animation-iteration-count: inherit;
}

.mobile-header {
  overflow: hidden;
}

.mobile-wrapper.active .mobile-header-logo {
  animation: animation_img 1s;
  animation-iteration-count: inherit;
}

.mobile-wrapper.close .mobile-menu-list {
  overflow: hidden;
}

.mobile-wrapper.close .mobile-menu-list li a {
  animation: animation_text_close 1s;
  animation-iteration-count: inherit;
}

.mobile-wrapper.close .avtoriz-mobile {
  animation: animation_btn_close 1s;
  animation-iteration-count: inherit;
}

.mobile-wrapper.close .mobile-header-logo {
  animation: animation_img_close 1s;
  animation-iteration-count: inherit;
}


.mobile-wrapper.close .container {
  transition-delay: 0.2s;
}



@keyframes animation_img {   
  0% {     
    transform: translateY(calc(-100% - 5px)); 
  }   
  50% {     
    transform: translateY(calc(-100% - 5px)); 
  } 
  100% {
    transform: translateY(0%); 
   }
}

@keyframes animation_text {   
  0% {     
    transform: translateX(-100%); 
  }   
  50% {     
    transform: translateX(-100%); 
  } 
  100% {
    transform: translateX(0%); 
   }
}


@keyframes animation_btn {   
  0% {     
    transform: scale(0.4); 
    opacity: 0;  
  }   
  50% {     
    transform: scale(0.4); 
    opacity: 0; 
  } 
  100% {
    transform: scale(1); 
    opacity: 1;
   }
}

@keyframes animation_img_close {   
  0% {     
    transform: translateY(0%); 
  }  
  50% {
    transform: translateY(calc(-100% - 5px)); 
  } 
  100% {
    transform: translateY(calc(-100% - 5px)); 
  }
}

@keyframes animation_text_close {   
  0% {     
    transform: translateY(0%); 
  }   
  50% {
    transform: translateY(-200px); 
  }
  100% {
    transform: translateY(-200px); 
  }
}


@keyframes animation_btn_close {   
  0% {     
    transform: scale(1); 
    opacity: 1;
  }   
  50% {
    transform: scale(0.4); 
    opacity: 0;  
  }
  100% {
    transform: scale(0.4); 
    opacity: 0;  
  }
}

@media screen and (max-width: 1200px) {
  .mobile-wrapper .container {
    display: block;
  }
 .mobile-wrapper.active {
    display: block;
  }
}
</style>