<template>
    <main id="plan">
        <MainPlan :data="data?.main_plan"/>
        <AdvantagesPlan :data="data?.advant_plan "/>
        <Rangs :data="data?.ranks"/>
        <FriendsMap :data_text="data?.friends_plan" :data="data?.popup_map"/>
        <PlanTables :data="data?.table_plan"/>
        <QuestionWS :data="data?.question_ws"/>
        <ManufacturingCompany :data="data?.manufacturing_company"/>
        <Questions :data="data?.questions_plan"/>
        <TaobitaOrder :data="data?.form"/>
    </main>
</template>
<script>
import SmoothScroll from 'smooth-scroll';
import router from "@/router";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

import MainPlan from "@/components/plan/MainPlan.vue";
import AdvantagesPlan from "@/components/plan/AdvantagesPlan.vue";
import Rangs from "@/components/plan/Rangs.vue";
import QuestionWS from "@/components/home/QuestionWS.vue";
import ManufacturingCompany from "@/components/home/ManufacturingCompany.vue";
import Questions from "@/components/home/Questions.vue";
import TaobitaOrder from "@/components/home/TaobitaOrder.vue";
import FriendsMap from '@/components/plan/FriendsMap.vue';
import PlanTables from '@/components/plan/PlanTables.vue';


export default {
    data() {
        return {
            data: null,
        };
    },
    components: {
        MainPlan,
        AdvantagesPlan,
        Rangs,
        QuestionWS,
        ManufacturingCompany,
        Questions,
        TaobitaOrder,
        FriendsMap,
        PlanTables
    },
    methods: {
        ...mapMutations({
        setLoading: "page/setLoading",
        }),
        async getData() {
        try {
            const result = await axios.get(
            "https://mayaydyryssova.com/v1/methods/home/?" +
                new URLSearchParams({ lang: this.currentLang }).toString()
            );
            // console.log(result);
            this.data = result.data;
            setTimeout(() => {
            this.setLoading(false);
            const href = router.currentRoute.value.href
            if (href) {
                if (href.indexOf('#') != -1) {
                window.location.href = href
                }
            }
            }, 2000);
        } catch (error) {
            router.push("/404");
            console.clear();
            console.error(error);
        }
        },
    },
    computed: {
        ...mapState({
            currentLang: state => state.currentLang,
        })
    },
    watch: {
        currentLang() {
        setTimeout(() => {
            this.getData();
        }, 500);
        },
    },
    mounted() {
        const scroll = new SmoothScroll('a[href*="#"]', {
        offset: 108,
        speed: 200 
        });
        this.getData();
    } 
}
</script>
<style>
    h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 112%;
    }

    #plan * {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .plan-subtitle {
        display: block;
        color: var(--blue);
        font-size: 20px;
        font-weight: 600;
        line-height: 116%;
        text-align: center;
        margin: 0px auto;
        margin-bottom: 20px;
    }

    #plan #question-ws,
    #plan #clinica {
        margin-bottom: 124px;
    }

    @media screen and (max-width: 1200px) {
        #plan #question-ws,
        #plan #clinica {
            margin-bottom: 96px
        }

        .plan-subtitle {
            font-size: 14px;
            font-weight: 700;
            line-height: 124%;
            margin-bottom: 8px;
        }
    }
</style>