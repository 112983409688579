export function sendComagic(name, phone, message) {
    Comagic.addOfflineRequest({
      name,
      phone,
      message
    }, function (o) { console.log(o); });
}

export function sendToComagic(name, phone, message) {
  Comagic.addOfflineRequest({
    name,
    phone,
    message
  }, function (o) { console.log(o); });
}