<template>
    <div class="tabs-container">
        <div class="tabs-nav" ref="tabs_nav">
            <slot name="nav"></slot>
        </div>
        <div class="tabs-content" ref="container">
            <div class="tabs-wrapper" ref="wrapper">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        initTabs() {
            const nav_items = this.$refs.tabs_nav.querySelectorAll('.nav-item'),
                wrapper = this.$refs.wrapper,
                swipe = this.$refs.container.clientWidth;
            let nav_index = 0;
            nav_items.forEach(nav_item => {
                nav_item.dataset.index = nav_index;
                nav_index++;
                nav_item.addEventListener('click', () => {
                    wrapper.style.transform = 'translateX(-' + nav_item.dataset.index * swipe + 'px)'
                })
            });
        }
    },
    mounted() {
        this.initTabs();
    }
}
</script>
<style>
.tabs-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid red;
}

.nav-item {
    cursor: pointer;
}

.tabs-content {
    width: 100%;
    overflow: hidden;
}

.tabs-wrapper {
    display: flex;
    flex-direction: row;
    transition: 0.8s;
}

.content-item {
    min-width: 100%;
    max-width: 100%;
}
</style>