<template>
    <section id="plan-tables-first">
        <div class="container">
            <!-- <pre>{{ data }}</pre> -->
            <div class="plan-subtitle" v-html="data?.subtitle"></div>
            <div class="plan-tables-title">
                <h2 v-html="data?.title"></h2>
                <!-- <div class="plan-tables-title-text" v-html="data?.text"></div> -->
            </div>
            <div class="two-tables">
                <RowTable id="account_status" :data="data?.tables.account_status"/>
                <RowTable id="partner" :data="data?.tables.partner"/>
            </div>
            <RowTable id="matching_bonus" :data="data?.tables.matching_bonus"/>

            <!-- <RowTable id="rank_bonus" :data="data?.tables.leader_bonus"/> -->

            <!-- <div class="two-tables">
                <RowTable id="linear_bonus" :data="data?.tables.linear_bonus"/>
                <RowTable id="structure_bonus" class="desktop" :data="data?.tables.structure_bonus"/>
                <div class="table-swiper-container mobile">
                    <div :class="scroll_table > width_title ? 'visible' : ''" class="table-swiper-prev" @click="slidePrev">
                        <svg width="12.000000" height="7.000000" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path id="Arrow 43" d="M1.21 2.5L3.17 0.52C3.38 0.32 3.38 0.01 3.17 -0.19C2.98 -0.39 2.67 -0.39 2.47 -0.19L-0.36 2.64C-0.56 2.84 -0.56 3.15 -0.36 3.35L2.47 6.18C2.67 6.38 2.98 6.38 3.17 6.18C3.38 5.98 3.38 5.67 3.17 5.47L1.21 3.5L12 3.5L12 2.5L1.21 2.5Z" fill="#727272" fill-opacity="1.000000" fill-rule="evenodd"/>
                        </svg>
                        Мотайте влево
                    </div>
                    <div :class="scroll_table < max_scroll ? 'visible' : ''" class="table-swiper-next" @click="slideNext">
                        Мотайте вправо
                        <svg width="13.000000" height="7.000000" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path id="Arrow 43" d="M10.78 2.5L8.82 0.52C8.61 0.32 8.61 0.01 8.82 -0.19C9.01 -0.39 9.32 -0.39 9.52 -0.19L12.35 2.64C12.55 2.84 12.55 3.15 12.35 3.35L9.52 6.18C9.32 6.38 9.01 6.38 8.82 6.18C8.61 5.98 8.61 5.67 8.82 5.47L10.78 3.5L0 3.5L0 2.5L10.78 2.5Z" fill="#727272" fill-opacity="1.000000" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div class="table-swiper" @scroll="setScroll" ref="table">
                        <div class="table-wrapper" ref="wrapper">
                            <RowTable id="structure_bonus" :data="data?.tables.structure_bonus"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="two-tables two-tables-reverse">
                <RowTable id="global_bonus" :data="data?.tables.global_bonus"/>
                <RowTable id="buisness_bonus" :data="data?.tables.buisness_bonus"/>
            </div> -->

        </div>
    </section>
    <section id="plan-tables">
        <div class="container achivments-container">
            <div class="achivments__caller">
                <div class="achivments__caller-left" :class="show_left ? '':'hidden'" @click="scrollLeft()">
                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.646446 4.35355C0.451184 4.15829 0.451184 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM13 4.5H1V3.5H13V4.5Z" fill="#727272"/>
                    </svg>
                    Мотайте влево
                </div>
                <span></span>
                <div class="achivments__caller-right" :class="show_right ? '':'hidden'" @click="scrollRight()">
                    Мотайте вправо
                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464466C8.97631 0.269204 8.65973 0.269204 8.46447 0.464466C8.2692 0.659728 8.2692 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM0 4.5H12V3.5H0V4.5Z" fill="#727272"/>
                    </svg>
                </div>
            </div>
            <div class="achivments-wrapper" :class="is_firefox ? 'firefox':''" @:scroll="onScroll()" ref="scroll_achivments">
                <div class="table-title rank_bonus_title" v-html="data?.tables.rank_bonus_title"></div>
                <div class="rank_bonus_table">
                    <div class="rank_bonus_table_main" id="last_column_1">
                        <div class="rank_bonus_table_first_item rank_bonus_table_item" v-for="item in data?.tables.rank_bonus_1.body">
                            <span v-for="obj in item" v-html="obj.c"></span>
                        </div>
                    </div>
                    <div class="rank_bonus_table_main" id="last_column_2">
                        <div class="rank_bonus_table_second_item rank_bonus_table_item" v-for="item in data?.tables.rank_bonus_2.body">
                            <span v-for="obj in item" v-html="obj.c"></span>
                        </div>
                    </div>
                    <div class="rank_bonus_table_main" id="last_column_3">
                        <div class="rank_bonus_table_thirg_item rank_bonus_table_item rank_bonus_table_second_item" v-for="item in data?.tables.rank_bonus_3.body">
                            <span v-for="obj in item" v-html="obj.c"></span>
                        </div>
                    </div>
                    <div class="rank_bonus_table_main" id="last_column_4">
                        <div class="rank_bonus_table_four_item rank_bonus_table_item rank_bonus_table_second_item" v-for="item in data?.tables.rank_bonus_4.body">
                            <span v-for="obj in item" v-html="obj.c"></span>
                        </div>
                    </div>
                    <RowTable id="achivments" :data="data?.tables.rank_bonus_5"/>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Swiper from 'swiper';
import SmoothScroll from 'smooth-scroll'
import "swiper/css";
import RowTable from '@/components/plan/RowTable.vue';
export default {
    props: {
        data: null,
    },
    data() {
        return {
            // scroll_table: 0,
            // max_scroll: 0,
            // width_title: 0,
            is_firefox: navigator.userAgent.includes("Firefox"),
            scroll_achivments: 0,
            show_left: false,
            show_right: true,
            count_slide_scroll: 0,
            array_tables: [0,139,298,387,460,560],
            reverse_array_tables: [560,460,387,298,139,0]
        }
    },
    components: {
        RowTable
    },
    methods: {
        // setScroll() {
        //     this.scroll_table = Math.abs(this.$refs.wrapper.getBoundingClientRect().x - 20)
        // },
        // slideNext() {
        //     if (this.scroll_table < this.$refs.wrapper.clientWidth) {
        //         this.$refs.table.scrollTo(this.$refs.wrapper.clientWidth + 100, 0);
        //         this.scroll_table = this.$refs.wrapper.clientWidth
        //     }
        // },
        // slidePrev() {
        //     if (this.scroll_table > 0) {
        //         this.$refs.table.scrollTo(0, 0);
        //         this.scroll_table = 0
        //     }
        // },
        // calculatScroll(){
        //     if (this.$refs.wrapper.querySelector('.table-title span')) {
        //         this.max_scroll = Math.abs( this.$refs.wrapper.clientWidth - this.$refs.wrapper.scrollWidth)
        //         this.width_title = this.$refs.wrapper.querySelector('.table-title span').clientWidth;
        //         setTimeout(() => {
        //             this.$refs.wrapper.querySelector('.table-title span').clientWidth
        //         }, 3000);
        //     }
        // },
        onScroll(){
            if (event.target == this.$refs.scroll_achivments) {
                if (event.target.scrollLeft == 0) {
                    this.show_left = false
                }else{
                    this.show_left = true
                }
                if (event.target.scrollLeft - (815 - event.target.clientWidth) >= -1) {
                    this.show_right = false
                }else{
                    this.show_right = true
                }
            }
        },
        scrollLeft(){
            let scroll_flag = true
            this.reverse_array_tables.forEach(element => {
                if (element < this.$refs.scroll_achivments.scrollLeft && scroll_flag) {
                    this.$refs.scroll_achivments.scrollTo(element, 0)
                    scroll_flag = false
                }
            });
        },
        scrollRight(){
            let scroll_flag = true
            if (this.count_slide_scroll < 5) {
                this.array_tables.forEach(element => {
                    if (element > this.$refs.scroll_achivments.scrollLeft && scroll_flag) {
                        this.$refs.scroll_achivments.scrollTo(element, 0)
                        scroll_flag = false
                    }
                });
            }
        }
    },
    mounted() {
        const interval = setInterval(() => {
            if (this.$refs.scroll_achivments) {
                if (this.$refs.scroll_achivments.clientWidth >= 815) {
                    this.show_right = false
                }
                clearInterval(interval);
            }
        }, 300);
        window.addEventListener('resize', ()=> {
            if (this.$refs.scroll_achivments) {
                if (this.$refs.scroll_achivments.clientWidth >= 815) {
                    this.show_right = false
                }else{
                    this.show_right = true
                }
            }
        })
        // const interval = setInterval(() => {
        //     if (this.$refs.wrapper) {
        //         this.calculatScroll();
        //         clearInterval(interval);
        //     }
        // }, 300);
        // window.addEventListener('resize', ()=> {
        //     this.calculatScroll();
        // })
    },
}
</script>
<style>
    .achivments-wrapper{
        max-width: 100%;
        overflow: auto;
        padding-bottom: 15px;
        Scroll-behavior: smooth 
    }

    .achivments__caller-left.hidden,
    .achivments__caller-right.hidden{
        opacity: 0;
        pointer-events: none;
    }

    .achivments__caller-left,
    .achivments__caller-right{
        opacity: 1;
        pointer-events: all;
        transition: .2s;
    }

    .achivments-wrapper.firefox{
        scrollbar-width: thin;
        scrollbar-color: var(--blue) var(--re-light-gray);
    }

    .achivments-wrapper::-webkit-scrollbar{
        width: 3px;
        height: 3px;
    }
    
    .achivments-wrapper::-webkit-scrollbar-track {
        border-radius: 3px;
        background: var(--re-light-gray); 
        margin-right: 20px
    }

    .achivments-wrapper::-webkit-scrollbar-thumb {
        background-color: var(--blue); 
        border-radius: 3px; 
        border: 0;
    }

    #achivments{
        /* min-width: 1100px; */
        margin-bottom: 0;
    }
    
    .achivments__caller{
        display: none;
    }

    #plan-tables{
        margin-bottom: 149px;
    }

    #plan #plan-tables-first{
        margin-bottom: 0;
    }

    #plan-tables-first .plan-subtitle {
        margin: 0px;
        margin-bottom: 15px;
        text-align: left;
    }

    #achivments .table-head{
        display: none;
    }

    #achivments .table-row:nth-child(1) .table-row-item:nth-child(2),
    #achivments .table-row:nth-child(2) .table-row-item:nth-child(2){
        display: none;
    }

    #achivments .table-row:nth-child(1) .table-row-item:nth-child(1),
    #achivments .table-row:nth-child(2) .table-row-item:nth-child(1){
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: span 2;
    }
    #achivments .table-row:nth-child(1) .table-row-item:nth-child(1){
        background-color: var(--re-green);
    }

    #achivments .table-row:nth-child(2) .table-row-item:nth-child(1){
        background-color: var(--re-mid-green);
    }

    #achivments .table-row{
        display: grid;
        grid-template-columns: 96px 1fr;
    }


    #achivments .table-row-item{
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }

    #achivments .table-row-item:first-of-type{
        /* width: 96px; */
        background-color: var(--biege);
    }


    .rank_bonus_table{
        display: grid;
        grid-template-columns: 3fr 3fr 1fr 1fr 4fr;
        gap: 4px;
    }

    .rank_bonus_table_main{
        display: grid;
        grid-template-rows: repeat(12, 42px);
        gap: 4px;
    }

    .rank_bonus_title{
        background-color: var(--re-more-green);
        font-size: 14px;
        font-weight: 700;
        line-height: 17.36px;
        height: 42px;
        padding: 8px 12px;
        border-radius: 4px;
        margin-bottom: 4px;
    }

    .rank_bonus_table_item{
        min-height: 42px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
    }
    
    .rank_bonus_table_first_item:first-of-type{
        grid-row: span 2;
        background-color: var(--re-green);
        height: 88px;
        font-size: 16px;
        line-height: 20px;
    }

    .rank_bonus_table_first_item{
        background-color: var(--orange);
    }

    .rank_bonus_table_second_item{
        background-color: var(--biege)
    }

    .rank_bonus_table_second_item:first-of-type{
        background-color: var(--re-green)
    }

    .rank_bonus_table_second_item:nth-child(2){
        background-color: var(--re-mid-green)
    }

    .rank_bonus_table_thirg_item:nth-child(4){
        grid-row: span 2;
    }
    .rank_bonus_table_thirg_item:nth-child(5),
    .rank_bonus_table_four_item:nth-child(6){
        grid-row: span 4;
    }

    .rank_bonus_table_four_item:nth-child(7){
        grid-row: span 2;
    }

    .plan-tables-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        margin-bottom: 50px
    }
    
    .plan-tables-title h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 116%;
        max-width: 603px;
    }

    .plan-tables-title-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 164%;
        max-width: 547px;
    }

    .table-swiper {
        overflow-x: auto;
        padding-bottom: 20px;
        scroll-behavior: smooth;
    }

    .table-swiper::-webkit-scrollbar {
        width: 100%;
        height: 3px;
        border-radius: 38px;
        background: var(--re-light-gray);
    }

    .table-swiper::-webkit-scrollbar-track {
        border-radius: 38px;
    }

    .table-swiper::-webkit-scrollbar-thumb {
        background-color: var(--blue);
        width: 40px;
        height: 3px;
    }

    .table-title span {
        display: block;
        width: max-content;
        max-width: 100%;
    }

    .two-tables {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 19px;
    }

    .table {
        margin-bottom: 72px;
    }

    #account_status .table-head .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #account_status .table-head,
    #account_status .table-row {
        gap: 4px;
    }

    #partner .table-head-item:first-of-type,
    #account_status .table-head-item:first-of-type{
        background: var(--orange);
        text-align: start;
        justify-content: flex-start;
    }
    #matching_bonus .table-head-item,
    #matching_bonus .table-row-item{
        background: var(--biege);;
        flex: 2;
    }

    #achivments .table-body{
        display: grid;
        grid-template-rows: repeat(12, 42px)
    }

    #matching_bonus .table-head-item:first-of-type,
    #matching_bonus .table-row-item:first-of-type{
        background: var(--orange);
        flex: 3;
    }

    #account_status .table-row-item,
    #account_status .table-head-item{
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
        height: 32px;
    }

    #partner {
        width: 100%;
    }

    #partner .table-head .table-head-item {
        height: 44px;
        font-weight: 600;
    }

    #partner .table-head,
    #partner .table-row {
        gap: 5px;
    }

    #partner .table-row-item{
        height: 29px;
        font-weight: 600;
    }

    #matching_bonus {
        width: 100%;
        margin-bottom: 68px;
    }

    #account_status .table-row-item:first-of-type,
    #partner .table-row-item:first-of-type{
        text-align: start;
        justify-content: flex-start;
    }

    #leader_bonus .table-row-item:first-of-type {
        background: var(--biege);
    }

    #leader_bonus .table-head .table-head-item,
    #leader_bonus .table-row-item {
        font-weight: 600;
    }

    #leader_bonus .table-row-item {
        height: max-content;
    }

    #structure_bonus {
        width: 75%;
    }

    #linear_bonus {
        width: 25%;
    }

    #linear_bonus .table-head-item {
        font-weight: 600;
    }

    #structure_bonus .table-row-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
        font-size: 9px;
        font-weight: 600;
        line-height: 132%;
    }  
    
    #structure_bonus .table-row-item:not(:first-of-type) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
    }

    #structure_bonus .table-head .table-head-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
    }

    #structure_bonus .table-head .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #structure_bonus .table-row-item.none {
        opacity: 0;
    }

    #structure_bonus .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #global_bonus {
        width: 62.5%;
    }

    #global_bonus .table-head .table-head-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
    }

    #global_bonus .table-row-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
        font-size: 18px;
        font-weight: 700;
        line-height: 132%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #global_bonus .table-row-item {
        font-size: 10px;
        font-weight: 600;
        line-height: 132%;
    }

    #buisness_bonus {
        width: 37.5%;
    }

    #buisness_bonus .table-row-item:first-of-type {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #buisness_bonus .table-head .table-head-item:first-of-type {
        max-width: 143px;
        min-width: 143px;
    }

    #buisness_bonus .table-row-item:last-of-type {
        max-width: 143px;
        min-width: 143px;
        font-size: 18px;
        font-weight: 700;
        line-height: 132%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #buisness_bonus .table-head-item,
    #global_bonus .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #matching_bonus .table-head .table-head-item {
        font-weight: 600;
    }

    #matching_bonus .table-head,
    #matching_bonus .table-row{
        gap: 9px;
    }

    @media screen and (max-width: 1200px) {

        .table.desktop {
            display: none;
        }

        .plan-tables-title {
            flex-direction: column;
            gap: 16px;
            margin-bottom: 48px;
            align-items: flex-start
        }

        .plan-tables-title h2 {
            font-size: 24px;
            text-align: left
        }

        .plan-tables-title-text {
            font-size: 12px;
        }

        .two-tables {
            display: flex;
            flex-direction: column;
        }

        .table {
            margin-bottom: 48px;
        }
        #account_status {
            flex-direction: column;
            gap: 4px
        }

        /* .rank_bonus_table_main{
            grid-template-rows: repeat(5, 42px) 61px 76px repeat(4, 61px) 42px
        } */

        #account_status .table-head-item{
            height: 46px;
            
        }

        #account_status .table-head-item:first-of-type {
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 132%;
            justify-content: center;
        }

        #account_status .table-head, #account_status .table-row{
            gap: 3px;
        }

        #account_status .table-row-item:first-of-type {
            text-align: center;
        }

        #account_status .table-row-item {
            font-size: 12px;
        }
        #partner .table-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 4px;
        }

        #partner .table-body {
            gap: 12px;
        }

        #partner .table-head-item.none {
            display: none;
        }

        #partner .table-head{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        #partner .table-head .table-head-item:first-of-type {
            grid-column: span 3;
            justify-content: center;
            height: 32px;
        }

        #partner .table-head .table-head-item{
            height: 46px;
        }

        #partner .table-row .table-row-item:first-of-type {
            grid-column: span 3;
            justify-content: center;
            display: flex;
        }

        /* .rank_bonus_table{
            grid-template-columns: 135px 155px 85px 85px 355px;
        } */

        #partner .table-row-item{
            height: 32px;
        }

        #partner {
            display: flex;
            gap: 8px;
            margin-bottom: 60px;
        }

        #partner .table-head .table-head-item {
            padding: 8px;
        }

        #partner .table-head {
            row-gap: 8px;
            column-gap: 4px;
        }

        .table-head .table-head-item {
            font-size: 12px;
            font-weight: 800;
            line-height: 132%;
            padding: 8px 12px;
        }

        #matching_bonus .mobile-table .table-body:last-of-type {
            margin-bottom: 0px;
        }

        .achivments__caller{
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
            color: #727272;
        }

        /* #matching_bonus .table-head .table-head-item:first-of-type,
        #matching_bonus .table-row-item:first-of-type {
            min-width: 76px;
            max-width: 76px;
            margin-right: 5px;
        } */

        #matching_bonus .table-head .table-head-item {
            width: 100%;
        }

        #matching_bonus .table-head-item.none {
            display: none;
        }

        #matching_bonus .table-head {
            gap: 2px;
        }

        #matching_bonus .table-row {
            gap: 2px;
        }

        /* #matching_bonus .table-row-item.none {
            display: none;
        } */

        #matching_bonus .table-head-item.none-opacity,
        #matching_bonus .table-row-item.none-opacity {
            min-width: 76px;
            max-width: 76px;
            margin-right: 5px;
            opacity: 0;
        }

        #matching_bonus .table-head .table-head-item {
            font-size: 11px;
            font-weight: 600;
            line-height: 132%;
            text-transform: capitalize;
            padding: 7px 0;
        }
        #matching_bonus .table-row-item{
            padding: 7px 0;
        }
        #matching_bonus .table-row-item:first-of-type {
            font-size: 11px;
            font-weight: 600;
            line-height: 132%;
        }

        #matching_bonus {
            gap: 4px;
            margin-bottom: 32px;
        }

        #account_status{
            margin-bottom: 41px;
        }

        /* .rank_bonus_title{
            justify-content: flex-start;
            width: 815px;
        } */

        /* #leader_bonus .table-head,
        #leader_bonus .table-row {
            flex-direction: column;
        }
        
        #leader_bonus .table-head,
        #leader_bonus .table-body {
            width: calc(50% - 2px);
        }

        #leader_bonus .table-title {
            width: 100%;
        }

        #leader_bonus {
            flex-direction: row;
            flex-wrap: wrap;
        } */
        #plan #plan-tables{
            margin-bottom: 255px;
        }
        #leader_bonus {
            gap: 8px;
        }

        #leader_bonus .first-table {
            display: none;
        }

        #leader_bonus .grid-table {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        #leader_bonus .table-row-item.none {
            display: none
        }

        #leader_bonus .table-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        #leader_bonus  .table-head-item {
            font-size: 12px;
            font-weight: 600;
            line-height: 132%;
            text-transform: capitalize;
            border-radius: 4px;
            padding: 8px;
            background: var(--sky);
        }

        #linear_bonus,
        #global_bonus,
        #buisness_bonus {
            width: 100%
        }

        #global_bonus {
            margin-bottom: 0px;
        }

        /* .two-tables-reverse {
            flex-direction: column-reverse;
        } */

        #buisness_bonus .table-head .table-head-item:first-of-type,
        #buisness_bonus .table-row-item:last-of-type {
            min-width: auto;
            max-width: 100%;
            width: 100%;
        }

        #buisness_bonus .table-row-item:first-of-type {
            font-size: 12px;
            padding: 12px;
        }

        #global_bonus .table-head .table-head-item:first-of-type,
        #global_bonus .table-row-item:first-of-type {
            max-width: 79px;
            min-width: 79px;
        }

        #structure_bonus {
            width: 100%;
            margin-bottom: 0px
        }

        .table-wrapper {
            position: relative;
            width: 100%;
        }

        .table-swiper-container {
            position: relative;
            margin-bottom: 45px;
        }

        .table-swiper-prev,
        .table-swiper-next {
            position: absolute;
            opacity: 0;
            transition: 0.5s;
            top: 8px;
            font-size: 12px;
            font-weight: 600;
            line-height: 132%;
            z-index: 10;
            color: var(--light_gray_text);
        }

        .table-swiper-prev.visible,
        .table-swiper-next.visible {
            opacity: 1;
        }

        .table-swiper-next {
            right: 20px;
        }

        .table-swiper-prev {
            left: 20px;
        }

        #plan-tables-first .plan-subtitle {
            margin-bottom: 3px;
        }

        .table-swiper {
            padding-bottom: 12px;
        }

        #leader_bonus .table-head-item {
            padding: 8px 12px;
        }

        #structure_bonus .table-row-item:not(:first-of-type) {
            padding: 8px
        }

        
        #achivments .table-body .table-row-item{
            height: 100%;
        }
    }
    @media screen and (max-width: 1090PX) {
        .rank_bonus_table {
            grid-template-columns: 135fr 155fr 85fr 69fr 355fr;
        }
        .rank_bonus_table_main {
            grid-template-rows: repeat(5, 42px) 61px 76px repeat(4, 61px) 42px;
        }
        #achivments .table-body{
            display: grid;
            grid-template-rows: repeat(5, 42px) 61px 76px repeat(4, 61px) 42px
        }
    }
    @media screen and (max-width: 871px) {
        .rank_bonus_title {
            justify-content: flex-start;
            width: 815px;
        }
        .rank_bonus_table {
            grid-template-columns: 135px 155px 85px 69px 355px;
        }
        .container.achivments-container{
            padding-right: 0;
        }
        .achivments__caller,
        .achivments-wrapper{
            padding-right: 20px;
        }
    }
    @media screen and (max-width: 715px) {
        .table-wrapper {
            width: 100%;
        }
        #structure_bonus {
            width: max-content;
            transition: 0.8s
        }
    }
</style>