<template>
  <section id="questions" v-if="data != null">
    <div class="container questions__wrapper">
      <div class="questions-header">
        <h2 class="questions-title" v-html="data?.title"></h2>
        <Button
          @click="popupOpen"
          :btn_class="'blue-stroke'"
          :value="data?.btn_text"
          class="question-btn"
        />
      </div>
      <div class="questions-content">
        <div class="questions-card" v-for="item in data?.questions">
          <div class="questions-card-title" v-html="item.title"></div>
          <div class="questions-card-text" v-html="item.text"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
export default {
  components: { Button },
  props: {
    data: null,
  },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'форма “Ответы на частые вопросы”');
      this.$store.commit("openPopup", true);
    },
  },
};
</script>
<style>
.questions__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.questions-header {
  display: flex;
  flex-direction: column;
  gap: 42px;
}
.questions-subtitle {
  font-size: 38px;
}
.questions-subtitle {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 148%; /* 26.64px */
}
.questions-content {
  margin-top: 17px;
  grid-area: 1 / 2 / 2 / 4;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.questions-card {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 24px;
}
.questions-card-title {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 132%; /* 26.4px */
}
.questions-card-text {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 172%; /* 27.52px */
}

@media screen and (max-width: 1200px) {
  .questions__wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  .questions-header {
    gap: 36px;
  }
  .questions-title {
    font-size: 42px;
  }
  .questions-subtitle {
    font-size: 16px;
  }
  .questions-content {
    margin-left: -20px;
    width: calc(100% + 40px);
    margin-top: 0;
    grid-template-columns: 1fr;
  }
  .questions-card {
    padding: 25px 20px;
  }
  .questions-card-title {
    font-size: 16px;
  }
  .questions-card-text {
    font-size: 13px;
  }
  .button.question-btn {
    width: 100%;
  }
}
</style>
