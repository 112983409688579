<template>
  <section :class="this.$store.state.currentLang" v-if="data != null">
    <div class="container healthy__wrapper">
      <div class="healthy-header">
        <h2 class="healthy-title" v-html="data?.title"></h2>
        <div class="healthy-subtitle" v-html="data?.subtitle"></div>
      </div>
      <div class="healthy-content">
        <div class="healthy-card" v-for="item in data?.cards">
          <div class="healthy-card-title">{{ item.title }}</div>
          <div class="healthy-card-text" v-html="item.text"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: null,
  },
};
</script>
<style>
.healthy__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.healthy-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.healthy-title {
  font-size: 38px;
}
.healthy-subtitle {
  font-size: 38px;
}
.healthy-subtitle {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 148%; /* 26.64px */
}
.healthy-content {
  margin-top: 17px;
  grid-area: 1 / 2 / 2 / 4;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px 20px;
}
.healthy-card {
  position: relative;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.healthy-card::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--blue);
}
.healthy-card-title {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 164%; /* 29.52px */
  text-transform: uppercase;
}
.healthy-card-text {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 164%; /* 22.96px */
}

.uz_cyr .healthy-card-text {
  color: #121212;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
}

@media screen and (max-width: 1200px) {
  .healthy__wrapper {
    display: flex;
    flex-direction: column;
    gap: 42px;
  }
  .healthy-header {
    gap: 12px;
  }
  .healthy-title {
    font-size: 24px;
  }
  .healthy-subtitle {
    font-size: 16px;
  }
  .healthy-content {
    margin-top: 0;
    grid-template-columns: 1fr;
    gap: 36px;
  }
  .healthy-card-title {
    font-size: 16px;
  }
  .healthy-card-text {
    font-size: 13px;
  }
}
</style>
