<template>
  <footer id="footer">
    <div class="container footer__wrapper">
      <div class="footer-left">
        <img :src="require('@/assets/img/taovita_footer.svg')" alt="" />
        <a class="footer_icon" href="https://taovita.ru/">
          <img :src="require('@/assets/img/footer_icon.svg')" alt="" />
        </a>
      </div>
      <div class="footer-right">
        <div class="footer-right-left">
          <ul class="footer__menu">
            <li class="footer__menu-item">
              <a class="footer__menu-link" :href="data?.links[0].link">{{ data?.links[0].text }}</a>
            </li>
             <li class="footer__menu-item">
              <a class="footer__menu-link" :href="data?.links[1].link">{{ data?.links[1].text }}</a>
              </li>
             <li class="footer__menu-item">
              <a class="footer__menu-link" :href="'https://mayaydyryssova.com/' + data?.links[2].link">{{ data?.links[2].text }}</a>
              </li>
             <li class="footer__menu-item">
              <a class="footer__menu-link" :href="'https://mayaydyryssova.com/' + data?.links[3].link">{{ data?.links[3].text }}</a>
              </li>
             <li class="footer__menu-item">
              <a class="footer__menu-link" :href="'https://mayaydyryssova.com/' + data?.links[4].link">{{ data?.links[4].text }}</a>
              </li>
             <li class="footer__menu-item">
            </li>
          </ul>
          <div class="footer__copywriting">
            <div class="copywriting" v-html="data?.year"></div>
          </div>
        </div>
        <div class="footer-right-right">
          <div class="footer__contacts">
            <div class="footer__contacts-item">
              <a class="email"  href="mailto:inform@taovita.ru"
                >inform@taovita.ru</a
              >
              <div class="address" v-html="city"></div>
              <div class="phone-container" @click="setHidden()">
                <a class="phone comagic_phone" :class="hidden_phone ? '' : 'hidden'" :href="'tel:' + data?.phone" v-html="data?.phone"></a>
                <span :class="hidden_phone ? '' : 'hidden'" v-html="data?.phone_show"></span>
              </div>
            </div>
            <div class="footer__contacts-item socials">
              <a v-if="data?.vk" :href="data?.vk" target="_blank"><img :src="require('@/assets/img/footer_vk.svg')" alt=""/></a>
              <a v-if="data?.youtube" :href="data?.youtube" target="_blank"><img :src="require('@/assets/img/footer_yt.svg')" alt=""/></a>
              <a v-if="data?.tg" :href="data?.tg" target="_blank"><img :src="require('@/assets/img/footer_tg.svg')" alt=""/></a>
              <a v-if="data?.whatsapp" :href="data?.whatsapp" target="_blank"><img :src="require('@/assets/img/footer_wa.svg')" alt=""/></a>
            </div>
          </div>
          <div class="footer__links" >
            <a :href="data?.user" target="_blank" v-html="data?.user_text"></a>
            <a :href="data?.pp" target="_blank" v-html="data?.pp_text"></a>
          </div>
        </div>
      </div>
      <div class="footer-bottom-mobile">
            <a :href="data?.user" target="_blank" v-html="data?.user_text"></a>
            <a :href="data?.pp" target="_blank" v-html="data?.pp_text"></a>
        <div v-html="data?.year"></div>
      </div>
    </div>
  </footer>
</template>
<script>
  import { sendComagic } from '@/assets/js/comagic.js'

  export default {
    data(){
      return {
        hidden_phone: true
      }
    },
    props: {
      data: null,
      city: null,
    },
    methods: {
      setHidden() {
        this.hidden_phone = false;
        sendComagic('', '', 'нажал на показать номер в подвале');
      }
    },
  }
</script>
<style scoped>
footer {
  background-color: var(--blue);
  border-radius: 64px 64px 0 0;
  margin-top: -64px;
}
.footer__wrapper {
  display: flex;
  gap: 126px;
  padding: 67px 20px 64px;
}
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-right {
  display: flex;
  gap: 96px;
}
.footer__menu {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: max-content;
}
.footer__menu-item {
}
.footer__menu-link {
  color: var(--white);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 132%; /* 21.12px */
}
.footer__contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
}
.footer__contacts-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.footer__contacts-item > * {
  color: var(--white);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 132%; /* 21.12px */
}
.email {
  text-decoration-line: underline;
}
.address,
.phone {
}
.footer__contacts-item.socials {
  flex-direction: row;
  gap: 23px;
}
.footer__copywriting {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: max-content;
}
.footer__copywriting > *,
.footer__links > * {
  color: var(--white);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 132%; /* 21.12px */
}
.footer__links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.footer-right-left,
.footer-right-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 47px;
}
.footer-bottom-mobile {
  display: none;
  flex-direction: column;
  gap: 8px;
}
.footer-bottom-mobile > * {
  color: var(--white);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 132%; /* 15.84px */
}

.footer__contacts .phone {
  position: relative;
  width: max-content;
  pointer-events: none;
  cursor: pointer;
  color: var(--white);
}

.phone-container .phone.hidden {
  pointer-events: all;
}

.phone-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  width: max-content;
}

.phone-container .phone::after {
  display: block;
  content: ' XX XX';
  position: absolute;
  top: 50%;
  right: -4px;
  transform: translateY(-50%);
  background: var(--blue);
  transition: 0.5s;
  /* letter-spacing: 0.25px; */
}

.phone-container span {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  transition: 0.5s;
}

.phone-container span.hidden {
  opacity: 0;
  pointer-events: none;
  max-width: 0px;
}

.phone-container .phone.hidden::after {
  opacity: 0;
}

@media screen and (max-width: 1200px) {
  .footer-bottom-mobile {
    display: flex;
  }
  footer {
    margin-top: -32px;
    border-radius: 32px 32px 0 0;
  }
  .footer_icon {
    display: none;
  }
  .footer__wrapper {
    flex-direction: column;
    padding: 50px 20px 80px;
    gap: 28px;
  }
  .footer-right {
    gap: 23px;
  }
  .footer__contacts {
    gap: 16px;
  }
  .footer__menu-link {
    font-size: 14px;
  }
  .footer__contacts-item > * {
    font-size: 12px;
    font-weight: 400;
  }
  .footer__copywriting,
  .footer__links {
    display: none;
  }
  .footer-left img {
    width: 124px;
    height: auto;
  }
  .footer__contacts-item {
    font-weight: 400;
  }
  .email {
    font-weight: 400;
  }
  .footer__contacts-item {
    font-weight: 400;
  }

  .phone-container {
    flex-direction: column;
    gap: 5px;
  }

  .phone-container span {
    font-size: 10px;
  }

  .phone-container .phone::after {
    right: -3px;
  }
}
</style>
