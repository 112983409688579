<template>
  <Popup @reset = "resetForm" type="openPopup">
    <template v-slot:header>
      <div class="popup-logo">
        <img :src="require('@/assets/img/popup.svg')" alt="" />
      </div>
      <h3 v-html="data?.title"></h3>
    </template>
    <form action="" class="order-popup-form">
      <div class="order-popup-form-block">
        <div class="order-popup-form-input">
          <input type="text" name="" id="" :placeholder="data?.input_text" v-model="name" />
          <span
            :class="
              error_name == '' ? 'popup-form-success' : 'popup-form-error'
            "
            >{{ error_name }}</span
          >
        </div>
        <div class="order-popup-form-input">
          <input
            type="tel"
            name=""
            id=""
            :placeholder="data?.input_phone"
            v-model="phone"
          />
          <span
            :class="
              error_phone == '' ? 'popup-form-success' : 'popup-form-error'
            "
            >{{ error_phone }}</span
          >
        </div>
      </div>
      <Button
        @click="createOrder"
        :btn_class="'white'"
        :value="data?.btn_text"
        class="order-popup-btn"
      />
      <div class="order-popup-privacy" v-html="data?.pp">
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from "../base/Popup.vue";
import Button from "./Button.vue";
import { replaceNumberForPaste, replaceToLatters } from "@/assets/js/helper.js";
import { sendComagic } from '@/assets/js/comagic.js'
import orderApi from "@/assets/js/api/order.js";
import router from '@/router';
export default {
  components: { Popup, Button },
  data() {
    return {
      name: "",
      phone: "",
      error_name: "",
      error_phone: "",
      is_validated: false,
      error_order: "",
    };
  },
  props: {
    data: null
  },
  methods: {
    resetForm() {
      this.name = "";
      this.phone = "";
      this.error_name = "";
      this.error_phone = "";
      this.is_validated = false;
    },
    async createOrder() {
      this.error_order = "";
      if (this.validate()) {
        let place = this.$store.state.currentPlace;
        if (router.currentRoute.value.path == '/marketing-plan') {
          place = "Заявка маркетинг-план";
        } else {
          place = "форма “Форма всплывающее окно”";
        }
        sendComagic(this.name, this.phone, place);
        const result = await orderApi.create(this.name, this.phone);
        

        if (result.success) {
          this.name = "";
          this.phone = "";
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.$store.commit('openPopup', false);
          this.$store.commit("successPopup", true);
        } else {
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.error_order = result.message;
        }
      }
    },
    validate() {
      let  req_fild = ''
      let phone_error = ''
      if (this.$store.state.currentLang == 'ru') {
        req_fild = 'Обязательное поле'
        phone_error = 'Введите существующий номер'
      }

      if (this.$store.state.currentLang == 'kz') {
        req_fild = 'Міндетті өріс'
        phone_error = 'Бар нөмірді енгізіңіз'
      }

      if (this.$store.state.currentLang == 'uz') {
        req_fild = 'Majburiy maydon'
        phone_error = 'Mavjud raqamni kiriting'
      }
      
      if (this.$store.state.currentLang == 'uz_cyr') {
        req_fild = 'Керакли майдон'
        phone_error = 'Мавжуд рақамни киритинг'
      }

      if (this.$store.state.currentLang == 'kg') {
        req_fild = 'Милдеттүү талаа'
        phone_error = 'Учурдагы Номерди киргизиңиз'
      }

      if (this.$store.state.currentLang == 'en') {
        req_fild = 'Required field'
        phone_error = 'Enter an existing numbe'
      }

      if (this.$store.state.currentLang == 'tj') {
        req_fild = 'Майдони ҳатмӣ'
        phone_error = 'Раками дохил кунед'
      }

      this.error_name = "";
      this.error_phone = "";
      this.error_email = "";

      if (this.name == "") {
        this.error_name = req_fild;
      }

      if (this.phone == "") {
        this.error_phone = req_fild;
      } 

      this.is_validated = true;

      return this.error_name == "" && this.error_phone == "";
    },
  },
  watch: {
    name(new_value) {
      this.name = replaceToLatters(new_value);
      if (this.is_validated) {
        this.validate();
      }
    },
    phone(new_value) {
      // this.phone = replaceNumberForPaste(new_value);
      this.phone = new_value.replace(/[^0-9]/g, '');
      if (this.is_validated) {
        this.validate();
      }
    },
  },
  mounted() {

  }
};
</script>
<style>
.order-popup-form {
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.order-popup-form-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.order-popup-form-input input {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.08);

  padding: 16px;
  outline: none;
  color: var(--white);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 132%; /* 18.48px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  width: 100%;
}
.order-popup-form-input input::placeholder {
  color: rgba(255, 255, 255, 0.64);
}
.order-popup-form-input input:focus,
.order-popup-form-input input:focus-visible,
.order-popup-form-input input:focus-within,
.order-popup-form-input input:not(:placeholder-shown) {
  border: 1px solid var(--white);
}
.order-popup-form-input {
  position: relative;
}
.order-popup-btn {
  margin-top: 12px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 12px;
}
.order-popup-privacy p,
.order-popup-privacy a {
  color: rgba(255, 255, 255, 0.64);
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 132%; /* 13.2px */
}

.order-popup-privacy a {
    text-decoration: underline;
}

.popup-form-success,
.popup-form-error {
  position: absolute;
  font-size: 13px;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
}
.popup-form-error {
  margin-top: 4px;
  display: block;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 132%; /* 14.52px */
  color: var(--errors);
}

.popup-form-success {
  color: #37a017;
}

.order-popup-form .order-popup-btn {
  font-size: 14px;
}
</style>
