<template>
  <Popup type="languagePopup" v-if="languages != null">
    <template v-slot:header>
      <div class="popup-logo">
        <img :src="require('@/assets/img/popup.svg')" alt="" />
      </div>
      <h3 v-html="data?.title"></h3>
    </template>
    <div class="popup-languages">
      <div class="popup-language-item">
        <input name="radio" id="ru" type="radio" />
        <label for="ru">{{ languages[0].text }}</label>
      </div>
      <div class="popup-language-item">
        <input name="radio" id="kz" type="radio" />
        <label for="kz">{{ languages[1].text }}</label>
      </div>
        <div class="popup-languages-row">
          <div class="popup-language-item">
          <input name="radio" id="uz" type="radio" />
          <label for="uz">{{ languages[2].text }}</label>
        </div>
        <div class="popup-language-item">
          <input name="radio" id="uz_cyr" type="radio" />
          <label for="uz_cyr">{{ languages[3].text }}</label>
        </div>
      </div>
      <div class="popup-language-item">
        <input name="radio" id="kg" type="radio" />
        <label for="kg">{{ languages[4].text }}</label>
      </div>
      <div class="popup-language-item">
        <input name="radio" id="en" type="radio" />
        <label for="en">{{ languages[5].text }}</label>
      </div>
      <div class="popup-language-item">
        <input name="radio" id="tj" type="radio" />
        <label for="tj">{{ languages[6].text }}</label>
      </div>
    </div>
    <Button
      :btn_class="'white'"
      :value="data?.btn_text"
      class="popup-languages-btn"
      @click="setLanguage"
    />
  </Popup>
</template>
<script>
import Popup from "../base/Popup.vue";
import Button from "./Button.vue";
export default {
  components: {
    Popup,
    Button,
  },
  props: {
    languages: null,
    data: null
  },
  methods: {
    setLanguage() {
      const all_lang = document.querySelectorAll("input[type='radio']");
      all_lang.forEach((item) => {
        if (item.checked) {
          this.$store.commit('setCurrentLang', item.id);
          localStorage.setItem('current_lang', item.id)
          this.$store.commit("languagePopup", false);
        }
      });
      this.$emit("setLang");
    },
  }, 
  emit: ['setLang'],
};
</script>
<style>
.popup-languages {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.popup-languages-row {
  display: flex;
  gap: 4px;
}
.popup-language-item {
  width: 100%;
}

.popup-language-item [type="radio"] {
  display: none;
}

.popup-language-item label {
  display: block;
  padding: 12px 34px;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 64px;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 132%; /* 15.84px */
  letter-spacing: 0.24px;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  transition: 0.5s;
  width: 100%;
  color: var(--white);
  user-select: none;
}

.popup-language-item label:after,
.popup-language-item label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.popup-language-item label:after {
  height: 10px;
  width: 10px;
  border: 1px solid var(--white);
  left: 16px;
  top: 50%;
  translate: 0 -50%;
}

.popup-language-item label::before {
  background-color: var(--white);
  height: 6px;
  width: 6px;
  top: 50%;
  translate: 0 -50%;
  left: calc(16px + 3px);
  scale: 5;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.popup-language-item [type="radio"]:checked ~ label::before {
  opacity: 1;
  visibility: visible;
  scale: 1;
}

.popup-language-item [type="radio"]:checked ~ label {
  background-color: var(--white);
  color: var(--blue);
}

.popup-language-item [type="radio"]:checked ~ label::after {
  border: 1px solid var(--blue);
}
.popup-language-item [type="radio"]:checked ~ label::before {
  background-color: var(--blue);
}

.popup-languages-btn {
  margin-top: 32px;
  width: 100%;
}
</style>
