<template>
  <div class="container privacy-policy" v-html="data?.content">

  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import router from "@/router";
import axios from "axios";

export default {
    data() {
        return {
            data: null
        }
    },
    props: {
        link: null
    },
    methods: {
        ...mapMutations({
            setLoading: "page/setLoading",
        }),
        async getData() {
            try {
                const result = await axios.get(
                "https://mayaydyryssova.com/v1/methods/privacy-policy/" + this.$route.params.id
                );
                this.data = result.data;
                setTimeout(() => {
                    this.setLoading(false);
                }, 2000);
            } catch (error) {
                router.push('/404');
                console.clear();
                console.error(error);
            }
        } 
    },
    async mounted () {
        this.getData();
    }
}
</script>

<style>

    *{
        font-family: Montserrat;
    }

    .container.privacy-policy {
        padding-top: 79px;
        margin-bottom: 169px;
    }

    .privacy-policy h1 {
        color: #121212;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 124%; /* 79.36px */
    }

    .privacy-policy h2 {
        color: #242424;
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%; /* 55.44px */
    }

    .privacy-policy h3 {
        color: #242424;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 124%;
        letter-spacing: 0.48px;
    }

    .privacy-policy p,
    .privacy-policy td {
        color: #242424;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
        letter-spacing: 0.32px;
    }

    @media screen and (max-width: 1200px) {
        .privacy-policy h1 {
            color: #121212;
            text-align: center;
            font-family: Montserrat;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .privacy-policy h2 {
            color: #242424;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .privacy-policy h3 {
            color: #242424;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
            letter-spacing: 0.32px;
        }

        .privacy-policy p  {
            color: #484848;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%; /* 21.32px */
        }

        .privacy-policy td {
            color: #484848;
            font-family: Montserrat;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%; /* 21.32px */
        }
    }

</style>