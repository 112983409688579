import Api from "@/assets/js/api/index.js";

class OrderApi extends Api {
  async create(name, phone) {
    try {
      const data = {
        name,
        phone,
      };

      const result = await this.send("methods/createOrder", data, "post");

      return result?.data;
    } catch (error) {
      router.push('/404');
      console.error(error);
      return false;
    }
  }
}

export default new OrderApi();
