import axios from "axios";
import router from "@/router";
axios.defaults.withCredentials = true;

class RegistrationApi {
  constructor(params) {
    this.default_params = {
      url: "https://mayaydyryssova.com/v1/",
    };

    this.params = Object.assign(this.default_params, params);
  }

  async registration(path, data = null, method = "get") {
    try {
      const axios_params = {
        method,
        url: this.params.url + path,
        data,
      };
      let result = await axios(axios_params);

      if (result.status != 200) {
        return;
      }

      return result;
    } catch (error) {
      if (error.response?.status) {
        switch (error.response.status) {
          case 403:
            router.push("/");
            break;

          default:
            break;
        }
      }
    }
  }
}

export default RegistrationApi;
