<template>
  <section id="together-section" v-if="data != null">
    <div class="container together-container">
      <img class="together-bg" src="@/assets/img/together-bg.png" alt="" />
      <h2 v-html="data?.title"></h2>
      <div class="together-list" v-html="data?.text"></div>
      <div class="together-text">
        <span v-html="data?.second_text"></span>
        <Button
          @click="popupOpen"
          class="together-btn"
          btn_class="blue"
          :value="data?.btn_text"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";

export default {
  components: {
    Button,
  },
  props: {
    data: null,
  },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'форма “Создать бизнес вместе с TAOVITA”');
      this.$store.commit("openPopup", true);
    },
  },
};
</script>
<style scoped>
#together-section {
  margin-bottom: 162px;
}

.container.together-container {
  position: relative;
  border-radius: 64px;
  background: #c2e8e1;
  padding-top: 58px;
  padding-left: 60px;
  padding-bottom: 56px;
  padding-right: 86px;
  overflow: hidden;
}

.together-container h2 {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%;
  margin-bottom: 16px;
}

.together-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 0;
  height: 100%;
}

.together-container ol,
li {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%; /* 26.24px */
}

.together-container ol {
  margin-bottom: 34px;
  max-width: 694px;
}

.together-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  margin-top: 34px;
}

.together-text span {
  color: #121212;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%;
  max-width: 400px;
}

.together-container .button {
  z-index: 2;
}

.together-list li {
  font-size: 16px;
}

@media screen and (max-width: 1200px) {
  .container.together-container {
    border-radius: 24px;
    padding: 0px 20px;
    padding-top: 37px;
    padding-bottom: 53px;
  }

  .together-bg {
    display: none;
  }

  .together-container h2 {
    color: var(--gray);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    margin-bottom: 12px;
  }

  .together-container ol,
  li {
    color: var(--dark_gray);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 172%;
  }

  .together-container ol {
    margin-bottom: 24px;
    max-width: 100%;
  }

  .together-text {
    flex-direction: column;
    gap: 50px;
  }

  .together-btn {
    width: 100%;
  }

  .together-text span {
    display: block;
    max-width: 100%;
    width: 100%;
    color: var(--blue);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
  }

  .together-list li {
      font-size: 13px;
  }
  .together-list ol {
    padding-left: 14px;
  }

  .together-text {
    margin-top: 24px;
  }
}
</style>
