<template>
    <div ref="widget_container" class="widget-container">
        <div ref="widget" class="widget " :class="is_drop ? 'drop' : ''" @click="first">
            <div class="head-widget" @click="drop">
                {{ data?.widget_help }}
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.0534754 7.59183L2.72727 10.2656L7.45989 5.533L12.139 10.2121L15 7.35119L7.64706 -0.00175476L0.0534754 7.59183Z" fill="white"/>
                </svg>
            </div>
            <div class="drop-content">
                <div class="drop-widget">
                    <span v-html="data?.widget_title"></span>
                    <div class="question-list">
                        <a :href="data?.widget_btn_link" target="_blank" class="question-item" v-html="data?.widget_btn"></a>
                        <a :href="item?.widget_question_link" class="question-item" v-for="item in data?.widget_question" v-html="item?.widget_question_title"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import router from "@/router";
export default {
    data() {
        return {
            is_drop: false,
            rout: router,
        }
    },
    props: {
        data: null
    },
    methods: {
        drop() {
            this.is_drop = !this.is_drop
        }
    },
    mounted() {
        window.addEventListener('click', async (e) => {
            if (this.$refs.widget && e.target) {
                if (!this.$refs.widget.contains(e.target)) {
                    this.is_drop = false
                }
            }
        })
        window.addEventListener('scroll', async ()=> {
            if (window.scrollY > 10) {
                if (this.$refs.widget_container) {
                    this.$refs.widget_container.classList.add('visible')
                }
            } else {
                if (this.$refs.widget_container) {
                    this.$refs.widget_container.classList.remove('visible')
                }
            }
        })
    },
}
</script>
<style>
    #plan + .widget-container{
        display: none;
    }
    .widget-container {
        position: fixed;
        right: 40px;
        bottom: 40px;
        opacity: 0;
        pointer-events: none;
        transition: 0.5s;
        z-index: 1000;
        width: max-content;
        height: auto;
    }
    .widget {
        border-radius: 24px;
        box-shadow: -4px -4px 8px 0px rgba(0, 0, 0, 0.04);
        background: var(--blue);
        width: 320px;
        color: var(--white);
        display: flex;
        flex-direction: column;
        padding: 22px 26px;
    }

    .widget-container.visible {
        opacity: 1;
        pointer-events: all;
    }

    .head-widget {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 700;
        line-height: 96%;
        cursor: pointer;
        padding: 22px 26px;
        margin: -22px -26px;
    }

    .drop-widget {
        padding-top: 0px;
        min-height: 0px;
    }

    .drop-widget span {
        font-size: 18px;
        font-weight: 700;
        line-height: 116%;
    }

    .question-list {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-top: 16px;
    }

    .question-list a {
        display: block;
        border-radius: 16px;
        background: rgb(255, 255, 255);
        padding: 8px 16px;
        color: var(--blue);
    }

    .drop-content {
        display: grid;
        grid-template-rows: 0fr;
        transition: 0.8s;
        overflow: hidden;
    }

    .widget.drop .drop-content {
        grid-template-rows: 1fr;
    }

    .widget.drop .drop-widget {
        padding-top: 9px;
    }

    .widget svg {
        transition: 0.8s;
    }

    .widget.drop svg {
        transform: rotate(180deg);
    }

    @media screen and (max-width: 1200px) {
        .widget-container {
            display: none;
            right: 50%;
            bottom: 10px;
            transform: translateX(50%);
        }
    }
</style>