<template>
  <section id="interes" :class="this.$store.state.currentLang" v-if="data != null">
    <div class="container interest__wrapper">
      <h2 class="interest-title" v-html="data?.title"></h2>
      <div class="interest-content">
        <div class="interest-card" v-for="(card, index) in data?.cards">
          <div class="interest-card-number">
            {{ (index + 1).toString().padStart(2, 0) }}
          </div>
          <div class="interest-card-text" v-html="card.text"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: null,
  },
};
</script>
<style>
#interes {
  margin-bottom: 120px;
}
.interest__wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.interest-title {
  text-align: center;
}
.interest-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 20px;
}
.interest-card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 26px;
  padding: 9px 15px 28px;
  border-radius: 32px;
  height: 200px;
  background-color: var(--biege);
}
.interest-card-number {
  color: var(--blue);
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 600;
  line-height: 102%; /* 65.28px */
}
.interest-card-text {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 148%; /* 26.64px */
}

#interes {
  margin-bottom: 96px;
}

@media screen and (max-width: 1200px) {

  .kz .interest-card {
    gap: 15px;
    padding: 20px 13px 26px;
  }

  .interest-title {
    font-size: 28px;
    line-height: 132%; /* 36.96px */
  }

  .interest__wrapper {
    gap: 42px;
  }
  .interest-content {
    grid-template-columns: 1fr;
  }
  .interest-card {
    justify-content: space-between;
    padding: 20px 8px 18px 26px;
    flex-direction: row;
    border-radius: 16px;
    gap: 20px;
    height: auto;
  }
  .uz .interest-card {
    padding: 20px 8px 18px 16px;
  }
  .uz_cyr .interest-card {
    padding: 20px 8px 18px 10px;
  }
  .interest-card-number {
    font-size: 36px;
  }
  .interest-card-text {
    width: 100%;
    font-size: 14px;
  }

  .uz .interest-card-text {
    color: #242424;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
  }
}
</style>
