<template>
    <section id="advant-plan" v-if="data != null">
        <div class="container">
            <div class="advant-title">
                <span class="plan-subtitle" v-html="data?.subtitle"></span>
                <h2 v-html="data?.title"></h2>
            </div>
            <div class="advant-list">
                <div class="advant-item" v-for="item in data?.advants ">
                    <hr>
                    <p v-html="item?.text"></p>
                </div>
            </div>
            <div class="advant-open">
                <p class="advant-open-popup" @click="popupOpen()">Узнайте подробнее у специалиста</p>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null
    },
    methods: {
        openRedirect() {
            this.$store.commit("openRedirect", true);
            this.$store.commit("setPopupLink", this.data?.advants_reg_link);
        },
        popupOpen() {
            this.$store.commit('currentPlace', 'маркетинг-плана “Узнайте подробнее у специалиста”');
            this.$store.commit("openPopup", true);
        },
    }
}
</script>
<style>
    .advant-open-popup{
        font-weight: 700;
        font-size: 13px;
        line-height: 17px;
        text-transform: uppercase;
        text-decoration: underline;
        color: var(--blue);
        cursor: pointer;
        width: max-content;
    }
    .advant-open{
        margin-top: 51px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .advant-list-open-form{
        cursor: pointer;
        text-decoration: underline;
    }
    #advant-plan h2 {
        max-width: 527px;
        text-align: center;
    }

    #plan #advant-plan {
        margin-bottom: 169px;
    }

    .advant-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
    }

    .advant-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 42px;
        column-gap: 21px;
    }

    .advant-item {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .advant-item p {
        font-size: 18px;
        font-weight: 700;
        line-height: 132%;
    }

    .advant-item hr {
        width: 100%;
        height: 0px;
        border: 2px solid var(--blue);
        border-radius: 4px;
    }

    .advant-reg {
        margin-bottom: 124px;
        background: var(--biege);
        border-radius: 24px;
        padding: 24px 0px;
    }

    .advant-reg .container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .advant-reg span {
        font-size: 14px;
        font-weight: 700;
        line-height: 132%;
    }

    .advant-reg a {
        font-size: 14px;
        font-weight: 700;
        line-height: 132%;
        text-transform: uppercase;
        color: var(--white);
        border-radius: 12px;
        background: var(--blue);
        padding: 10px 24px;
        text-align: center;
        width: 100%;
        max-width: 320px;
        margin: 0px auto;
        transition: 0.8s;
    }

    .advant-reg a:hover {
        background: rgba(10, 151, 160, 0.84);
    }

    @media screen and (max-width: 1200px) {
        .advant-open-popup{
        font-weight: 700;
        font-size: 13px;
        line-height: 17px;
        text-transform: uppercase;
        text-decoration: underline;
        color: var(--blue);
        cursor: pointer;
        width: max-content;
        }
        .advant-open{
            margin-top: 48px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        #plan #advant-plan {
            margin-bottom: 243px;
        }

        .advant-list {
            grid-template-columns: 1fr;
            row-gap: 24px;
        }

        .advant-title {
            margin-bottom: 32px;
        }

        .advant-item p {
            font-size: 14px;
            font-weight: 700;
            line-height: 132%;
        }

        .advant-item {
            gap: 10px;
        }
    }
</style>