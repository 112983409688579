<template>
  <section :class="this.$store.state.currentLang" v-if="data != null">
    <div class="container easy__wrapper">
      <div class="easy-header">
        <h2 class="easy-title" v-html="data?.title"></h2>
        <div class="esy-subtitle" v-html="data?.desc"></div>
      </div>
      <div class="easy-content">
        <div class="easy-card" v-for="item in data?.cards">
          <div class="easy-card-img">
            <img :src="item.img" alt="" />
          </div>
          <div class="easy-card-title" v-html="item.title"></div>
          <div class="easy-card-text" v-html="item.text"></div>
        </div>
      </div>
      <Button
        @click="popupOpen"
        :value="data?.btn_text"
        :btn_class="'blue'"
        class="easy-btn"
      />
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
export default {
  props: {
      data: null,
  },
  components: { Button },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'форма “Бизнес, которым удобно управлять');
      this.$store.commit("openPopup", true);
    },
  },
  mounted() {
    
  }
};
</script>
<style>
.easy__wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.easy-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.easy-title {
  text-align: center;
}
.esy-subtitle {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 164%; /* 26.24px */
  text-align: center;
}
.easy-content {
  display: flex;
}
.easy-card {
  width: 100%;
  border-left: 2px solid var(--sky);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 0 45px;
}
.easy-card:last-of-type {
  border-right: 2px solid var(--sky);
}
.easy-card-img {
  width: 85px;
  height: 85px;
  aspect-ratio: 1 / 1;
}
.easy-card-img img {
  width: 100%;
  height: 100%;
}
.easy-card-title {
  color: var(--dark_gray);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 132%; /* 23.76px */
  text-transform: uppercase;
}
.easy-card-text {
  color: var(--dark_gray);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 164%; /* 22.96px */
}
.easy-btn {
  margin-top: 8px;
  align-self: center;
}

.uz_cyr .easy-card {
  padding: 0px 39px;
}

.uz .easy-card {
  padding: 0px 24px;
}

@media screen and (max-width: 1200px) {
  .easy-content {
    flex-direction: column;
  }
  .easy-title {
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 34.72px */
  }
  .easy-title br.desktop {
    display: none;
  }
  .esy-subtitle {
    font-size: 14px;
    max-width: 299px;
  }
  .uz_cyr .esy-subtitle {
    font-size: 13px;
    max-width: 100%;
  }
  .esy-subtitle br {
    display: none;
  }
  .easy-card:not(:first-of-type) {
    padding: 36px 0;
  }
  .easy-card {
    border: none;
    border-bottom: 2px solid var(--sky);
    padding: 0;
    padding-bottom: 36px;
    gap: 8px;
  }
  .easy-card:last-of-type {
    border-right: none;
  }
  .easy-btn {
    margin: 0;
  }
  .easy-card-title {
    font-size: 16px;
  }
  .easy-card-text {
    font-size: 13px;
  }
  .easy-card-img {
    margin-bottom: 12px;
  }
  .easy__wrapper .button {
    font-size: 14px;
    width: 100%;
  }
  .uz .easy-card {
    padding: 36px 0px;
  }

  .uz .easy-card-title {
    max-width: 275px;
  }

  .uz_cyr .easy-card {
    padding: 36px 0px;
  }
}
</style>
