<template>
    <section id="product-slider" v-if="data != null">
        <div ref="container" class="container">
            <div class="swiper-container" :class="item?.slides.length > 5 ? '':'no-swiper'" v-for="item in data">
                <div class="swiper-container-head">
                    <h2 v-html="item?.title"></h2>
                    <div class="swiper-container-btns">
                        <div :class="'catalog-prev-' + item?.name">
                            <svg class="desktop" width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="32" rx="16" transform="matrix(-1 0 0 1 56 0)" fill="#0A97A0"/>
                                <path d="M15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929L21.6569 8.92893C22.0474 8.53841 22.6805 8.53841 23.0711 8.92893C23.4616 9.31946 23.4616 9.95262 23.0711 10.3431L17.4142 16L23.0711 21.6569C23.4616 22.0474 23.4616 22.6805 23.0711 23.0711C22.6805 23.4616 22.0474 23.4616 21.6569 23.0711L15.2929 16.7071ZM40 17L16 17V15L40 15V17Z" fill="white"/>
                            </svg>
                            <svg class="mobile" width="20" height="33" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="32" rx="10" transform="matrix(-1 0 0 1 20 0.0546875)" fill="#0A97A0"/>
                                <path d="M5.29289 16.7618C4.90237 16.3713 4.90237 15.7381 5.29289 15.3476L11.6569 8.98362C12.0474 8.5931 12.6805 8.5931 13.0711 8.98362C13.4616 9.37414 13.4616 10.0073 13.0711 10.3978L7.41421 16.0547L13.0711 21.7115C13.4616 22.1021 13.4616 22.7352 13.0711 23.1258C12.6805 23.5163 12.0474 23.5163 11.6569 23.1258L5.29289 16.7618ZM7 17.0547H6V15.0547H7V17.0547Z" fill="white"/>
                            </svg>
                        </div>
                        <div :class="'catalog-next-' + item?.name">
                            <svg class="desktop" width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="32" rx="16" fill="#0A97A0"/>
                                <path d="M40.7071 16.7071C41.0976 16.3166 41.0976 15.6834 40.7071 15.2929L34.3431 8.92893C33.9526 8.53841 33.3195 8.53841 32.9289 8.92893C32.5384 9.31946 32.5384 9.95262 32.9289 10.3431L38.5858 16L32.9289 21.6569C32.5384 22.0474 32.5384 22.6805 32.9289 23.0711C33.3195 23.4616 33.9526 23.4616 34.3431 23.0711L40.7071 16.7071ZM16 17L40 17V15L16 15V17Z" fill="white"/>
                            </svg>
                            <svg class="mobile" width="20" height="33" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.0546875" width="20" height="32" rx="10" fill="#0A97A0"/>
                                <path d="M14.7071 16.7618C15.0976 16.3713 15.0976 15.7381 14.7071 15.3476L8.34315 8.98362C7.95262 8.5931 7.31946 8.5931 6.92893 8.98362C6.53841 9.37414 6.53841 10.0073 6.92893 10.3978L12.5858 16.0547L6.92893 21.7115C6.53841 22.1021 6.53841 22.7352 6.92893 23.1258C7.31946 23.5163 7.95262 23.5163 8.34315 23.1258L14.7071 16.7618ZM13 17.0547H14V15.0547H13V17.0547Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div :class="'swiper product-swiper product-swiper-' + item?.name">
                    <div class="swiper-wrapper">
                        <a class="swiper-product-item swiper-slide" :href="slide.btn_link" v-for="slide in item?.slides">
                            <div class="swiper-product-item-img">
                                <img :src="slide?.img" alt="">
                            </div>
                            <p v-html="slide?.title"></p>
                            <div class="swiper-product-item-prices">
                                <div class="swiper-product-item-prices-rub">
                                    <span class="swiper-product-item-price-partner" v-html="slide?.price_partner"></span>
                                    <span class="swiper-product-item-price" v-html="slide?.price"></span>
                                </div>
                                <span class="swiper-product-item-cv" v-html="slide?.code"></span>
                            </div>
                            <button class="swiper-product-item-btn"><span>{{ slide?.product_watch }}</span></button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Swiper from 'swiper';

export default {
    props: {
        data: null
    },
    data() {
        return {
            slider: null
        }
    },
    mounted() {
        const interval = setInterval(() => {
        if (this.$refs.container && this.data) {
            this.data.forEach(item => {
                if (window.clientWidtch > 1200 && item?.slides.lengtch < 5) {} else {
                    this.slider = new Swiper('.product-swiper-' + item?.name, {
                    mousewheel: false,
                    spaceBetween: 8,
                    slidesPerView: 'auto',
                    navigation: {
                        nextEl: ".catalog-next-" + item?.name,
                        prevEl: ".catalog-prev-" + item?.name,
                    },
                    breakpoints: {
                        1200: {
                            spaceBetween: 8,
                        }
                    }
                    });
                }
            });
            clearInterval(interval);
        }
        }, 50);    
    },
}
</script>
<style>
    .swiper-product-item{
        background-color: var(--re-light-green);
        border-radius: 24px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 294px;
    }

    .swiper-product-item-img{
        padding: 36px;
        width: 100%;
        height: 205px;
        border-radius: 16px;
        background-color: var(--white);
    }

    .swiper-product-item-img img{
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        margin-bottom: 4px;
    }

    .swiper-product-item p {
        font-size: 15px;
        font-weight: 700;
        line-height: 136%;
        transition: .5s;
    }

    .swiper-product-item:hover p {
        color: var(--blue);
    }

    .swiper-product-item-prices{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .swiper-product-item-prices span.swiper-product-item-price-partner{
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
        color: var(--gray);
    }
    
    .swiper-product-item-prices span.swiper-product-item-price{
        font-size: 13px;
        font-weight: 600;
        line-height: 14px;
        margin-left: 6px;
        text-decoration: line-through;
        color: var(--re-light-gray-text)
    }
    
    .swiper-product-item-prices span.swiper-product-item-cv{
        font-size: 13px;
        font-weight: 600;
        line-height: 14px;
        color: var(--re-light-gray-text);
        height: 16px;
    }

    .swiper-product-item-btn {
        width: 100%;
        padding: 16px 0;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        background-color: var(--white);
        border-radius: 16px;
        font-weight: 700;
        border: 0;
        position: relative;
    }

    .swiper-product-item-btn::after {
        content: "";
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top:0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: 16px;
        background: linear-gradient(to left, #018d96 0%, #01adb7 100%);
        transition: .5s;
        z-index: 10;
    }

    .swiper-product-item:hover .swiper-product-item-btn::after {
        opacity: 1;
    }

    .swiper-product-item .swiper-product-item-btn span {
        transition: .5s;
        color: var(--re-balck);
        position: relative;
        z-index: 15;
    }
    
    .swiper-product-item:hover .swiper-product-item-btn span {
        color: var(--white);
    }
    
    .product-swiper {
        overflow-x: visible;
    }

    #product-slider {
        overflow-x: hidden;
    }

    .product-swiper-img {
        width: 270px;
        height: 160px;
    }

    .swiper-container-head {
        margin-bottom: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .swiper-container-btns {
        display: flex;
        flex-direction: row;
        gap: 6px;
    }

    .swiper-container-btns svg {
        cursor: pointer;
    }

    .swiper-container {
        margin-bottom: 56px;
    }

    .swiper-container:last-of-type {
        margin-bottom: 0px;
    }

    .slide-text {
        font-size: 13px;
        font-weight: 700;
        line-height: 136%;
    }

    .slide-price {
        font-size: 16px;
        font-weight: 700;
        line-height: 136%;
        margin-top: -4px;
    }

    .slide-btn {
        display: block;
        width: 100%;
        font-size: 13px;
        font-weight: 700;
        line-height: 132%;
        color: var(--blue);
        border: 1px solid var(--blue);
        text-align: center;
        padding: 8px 16px;
        border-radius: 12px;
        margin-bottom: 5px;
    }

    .product-swiper .swiper-slide:hover .slide-text,
    .product-swiper .swiper-slide:hover .slide-price,
    .product-swiper .swiper-slide:hover .slide-btn {
        color: var(--blue);
    }

    .product-swiper .swiper-slide:hover .slide-btn {
        background: var(--blue);
        color: var(--white);
    }

    .slide-text,
    .slide-price,
    .slide-btn {
        transition: 0.5s;
    }

    .no-swiper.swiper-wrapper {
        display: flex;
        flex-direction: row;
        gap: 40px;
    }

    /* .no-swiper .swiper-container-btns {
        display: none;
    } */

    @media screen and (max-width: 1200px) {
        .swiper-container-head h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 124%;
        }

        .slide-text {
            font-size: 13px;
            font-weight: 700;
            line-height: 136%;
        }

        .product-swiper .swiper-slide {
            width: auto;
        }

        .product-swiper-img {
            width: 280px;
            height: 160px;
        }

        .no-swiper .swiper-wrapper {
            gap: 8px;
        }

        .no-swiper .swiper-container-btns {
            display: flex;
        }
    }
</style>