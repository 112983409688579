<template>
  <Popup type="mentorPopup" class="mentor-popup">
    <template v-slot:header>
      <div class="popup-logo">
        <img :src="require('@/assets/img/logo_m.png')" alt="" />
      </div>
      <h3 v-html="data?.title"></h3>
    </template>
    <div class="mentors-list">
      <div
        class="mentor-item"
        v-for="(item, index) in data?.mentors"
        @click="setMentor(item, index)"
        :class="is_active == index ? 'active' : ''"
      >
        <img :src="item.img" alt="" class="mentor-item-img" />
        <div class="mentor-item-info">
          <div class="mentor-item-name">{{ item.name }}</div>
          <div class="mentor-item-desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <Button
      :value="data?.btn_text"
      :btn_class="'blue'"
      class="mentor-save-btn"
      @click="saveMentor"
    />
  </Popup>
</template>
<script>
import Popup from "../base/Popup.vue";
import Button from "./Button.vue";
export default {
  components: { Popup, Button },
  props: {
    data: null
  },
  methods: {
    setMentor(item, index) {
      if (this.is_active != index) {
        this.is_active = index;
        this.mentor_info = item;
      }
    },
    saveMentor() {
      this.$store.commit("mentorInfo", this.mentor_info);
      this.$store.commit("mentorPopup", false);
    },
  },
};
</script>
<style>
.mentor-popup .popup-container {
  width: auto;
  background-color: var(--white);
  padding: 48px;
}

.mentor-popup .popup-header {
  gap: 10px;
  align-items: flex-start;
}

.mentor-popup h3 {
  color: #363636;
}
.mentor-popup .popup-content {
  margin-top: 28px;
}
.mentor-popup .popup-header svg path {
  fill: #363636;
  transition: 0.5s;
}
.mentor-popup .popup-header svg:hover path {
  fill: var(--dark_gray);
}
.mentors-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.mentor-item {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  cursor: pointer;
}
.mentor-item.active {
  /* border: 2px solid var(--blue); */
  border: 1px solid var(--blue);
}
.mentor-item-img {
  width: 64px;
  aspect-ratio: 1;
  border-radius: 8px;
}
.mentor-item-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.mentor-item-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 132%;
  letter-spacing: 0.02px;
  text-transform: uppercase;
}
.mentor-item-desc {
  font-weight: 50;
  font-size: 14px;
  line-height: 132%;
  color: rgba(54, 54, 54, 0.64);
}

.mentor-save-btn {
  margin-top: 35px;
  align-self: flex-end;
}

@media screen and (max-width: 1200px) {
  .mentors-list {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .mentor-item {
    align-items: flex-start;
  }
  .mentor-popup .popup-container {
    width: calc(100% - 16px);
    padding: 40px 24px;
  }
  .mentor-popup h3 {
    font-size: 28px;
    text-align: left;
  }
  .mentor-popup .popup-content {
    margin-top: 24px;
  }
  .mentor-item-img {
    width: 42px;
  }
  .mentor-item-name {
    font-size: 14px;
  }
  .mentor-item-desc {
    font-size: 12px;
  }
  .mentor-save-btn {
    margin-top: 28px;
    width: 100%;
  }
  .mentor-popup .popup-header svg {
    right: 20px;
    top: 20px;
  }
}
</style>
