<template>
  <section v-if="data != null">
    <div class="container marketing__wrapper">
      <div class="marketing-header">
        <h2 class="marketing-title" v-html="data?.title"></h2>
        <div class="marketing-subtitle" v-html="data?.subtitle">
        </div>
      </div>
      <div class="marketing-content">
        <div v-html="data?.content"></div>
        <Button
          @click = "openRedirect"
          :btn_class="'blue'"
          :value="data?.btn_text"
          class="marketing-btn"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
export default {
  components: {
    Button,
  },
  props: {
    data: null,
  },
  methods: {
    openRedirect() {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", this.data?.link);
    },
  }
};
</script>
<style>
.marketing__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.marketing-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.marketing-title {
}
.marketing-subtitle {
  color: var(--blue);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 142%; /* 28.4px */
}
.marketing-content {
  grid-area: 1 / 2 / 2 / 4;
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.marketing-content ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.marketing-content ul li {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 148%; /* 23.68px */
}
.marketing-btn {
}
.btn {
}

@media screen and (max-width: 1200px) {
  .marketing__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .marketing-header {
    gap: 12px;
  }
  .marketing-title {
    font-size: 32px;
  }
  .marketing-subtitle {
    font-size: 16px;
  }
  .marketing-content ul {
    gap: 16px;
  }
  .marketing-content ul li {
    font-size: 14px;
  }
  .button.marketing-btn {
    width: 100%;
  }
}
</style>
