<template>
    <div class="popup-question" :class="this.$store.state.questionpopup == true ? 'active' : ''">
        <div class="popup-bg" @click="popupClose()"></div>
        <div class="popup-question-container">    
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="close-popup-question" @click="popupClose()">
                <path opacity="0.72" d="M13.148 0L16 2.85205L10.9519 7.90018L15.943 12.8913L12.8913 15.943L7.90018 10.9519L2.85205 16L0 13.148L5.04813 8.09982L0.0570403 3.10873L3.10873 0.0570403L8.09982 5.04813L13.148 0Z" fill="#363636"/>
            </svg>
            <div class="question-container">
                <h3 v-html="data?.title"></h3>
                <div class="text-question" v-html="data?.text"></div>
                <a :href="data?.link" class="question-btn-ws" v-html="answer_btn"></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: null,
        answer_btn: ''
    },
    data() {
        return {
            active_index: 0,
            active_marker: null
        }
    },
    methods: {
        popupClose() {
            this.$store.commit("setQuestionPopup", false);
            document.querySelector('body').style.overflow = "";
        }
    },
}
</script>
<style>
     .popup-question {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100lvh;
        opacity: 0;
        pointer-events: none;
        z-index: 1000;
        transition: 0.8s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .popup-question.active {
        opacity: 1;
        pointer-events: all;
    }

    .title-popup-container h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 112%;
    }

    .popup-question-container {
        overflow-y: auto;
        position: relative;
        background-color: var(--white);
        height: auto;
        z-index: 100;
        padding: 50px 45px;
        border-radius: 32px;
        width: 1200px;
    }

    .close-popup-question {
        position: absolute;
        top: 44px;
        right: 44px;
        cursor: pointer;
    }

    .title-popup-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-end;
    }

    .question-container h3 {
        font-size: 22px;
        font-weight: 700;
        line-height: 132%;
        margin-bottom: 16px;
    }

    .question-container p {
        font-size: 15px;
        font-weight: 500;
        line-height: 172%;
    }

    .question-btn-ws {
        margin: 0px auto;
        font-size: 14px;
        font-weight: 700;
        line-height: 136%;
        color: var(--blue);
        padding: 12px 24px;
        border: 1px solid var(--blue);
        border-radius: 12px;
        margin-top: 34px;
        display: block;
        width: max-content;
        transition: 0.5s;
    }

    .question-btn-ws:hover {
        background: var(--blue);
        color: var(--white);
    }

    .text-question {
        font-size: 15px;
        font-weight: 500;
        line-height: 172%;
    }

    @media screen and (max-width: 1200px) {
        .popup-question-container {
            width: 328px;
            height: 559px;
            padding: 24px;
            border-radius: 32px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        .question-container {
            position: relative;
            width: 100%;
        }

        .title-popup-container {
            grid-template-columns: 1fr;
        }

        .question-btn-ws {
            width: 100%;
        }

        .question-container h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 132%;
            padding-right: 40px;
        }

        .popup-question-container {
            padding-top: 32px;
        }

        .text-question {
            font-size: 12px;
            font-weight: 500;
            line-height: 172%;
        }

        .close-popup-question {
            top: 32px;
            right: 28px;
            z-index: 100;
        }

        .question-btn-ws {
            text-align: center;
            font-size: 13px;
            font-weight: 700;
            line-height: 136%;
            margin-top: 16px;
        }
    }
</style>