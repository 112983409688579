<template>
    <section :class="this.$store.state.currentLang" v-if="data != null" id="question-ws">
        <div class="container">
            <img class="ws-img" :src="require('@/assets/img/ws.png')" alt="">
            <h2 v-html="data?.title"></h2>
            <p v-html="data?.text"></p>
            <div class="question-btns-list">
                <a @click="redirectComagic" v-for="item in data?.buttons" :href="item?.link" class="question-btns-item" v-html="item?.text"></a>
            </div>
            <a @click="redirectComagic" :href="data?.btn_ws.link" class="ws-btn">
                <img class="ws-btn-img" :src="require('@/assets/img/ws-white.png')" alt="">
                {{ data?.btn_ws.text }}
            </a>
        </div>
    </section>
</template>
<script>
import router from '@/router';
export default {
    props: {
        data: null
    },
    methods: {
        redirectComagic() {
            if (router.currentRoute.value.path == '/marketing-plan') {
                sendToComagic('', '', 'маркетинг-план переход в вотс ап')
            } else {
                sendToComagic('', '', 'главная страница переход в вотс ап')
            }
        }
    }
}
</script>
<style>
    #question-ws .container {
        position: relative;
        border-radius: 64px;
        background: rgb(253, 245, 230);
        padding-top: 50px;
        padding-bottom: 60px;
        padding-left: 83px;
        padding-right: 66px;
    }

    #question-ws h2 {
        font-family: "Montserrat";
        font-size: 42px;
        font-weight: 700;
        line-height: 116%;
        text-align: left;
    }

    #question-ws p {
        margin-top: 12px;
        margin-bottom: 38px;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 600;
        line-height: 116%;
    }

    .question-btns-list {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 7px;
        row-gap: 9px;
    }

    .question-btns-item,
    .ws-btn {
        box-sizing: border-box;
        border: 1px solid var(--blue);
        border-radius: 16px;
        background: var(--blue);
        padding: 8px 16px;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 132%;
        color: var(--white);
        transition: 0.5s;
    }

    .question-btns-item:hover,
    .ws-btn:hover {
        background: rgba(10, 151, 160, 0.84);
        border-color: rgba(10, 151, 160, 0.84);
    }

    .ws-btn {
        position: absolute;
        right: 66px;
        top: 71px;
        padding: 8px;
        font-size: 16px;
        width: 267px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 53px;
    }

    .ws-img {
        position: absolute;
        top: 25px;
        right: 302px;
    }

    .ws-btn-img {
        display: none;
    }

    @media screen and (max-width: 1200px) {
        #question-ws {
            padding: 0px 20px;
        }

        .ws-btn-img {
            display: block;
        }

        #question-ws .container {
            padding: 24px;
            border-radius: 32px;
        }

        #question-ws h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 108%;
        }

        #question-ws p {
            font-size: 12px;
            font-weight: 600;
            line-height: 116%;
            margin-top: 12px;
            margin-bottom: 32px;
        }

        #plan #question-ws p {
            margin-bottom: 28px;
        }

        #plan .ws-btn {
            margin-top: 42px;
        }

        .ws-btn {
            position: relative;
            top: 0px;
            right: 0px;
            margin-top: 32px;
            gap: 8px;
            font-size: 14px;
            font-weight: 700;
            line-height: 132%;
        }

        .ws-btn,
        .question-btns-item,
        .question-btns-list {
            width: 100%;
        }

        .question-btns-item {
            font-size: 11px;
            font-weight: 600;
            line-height: 132%;
        }

        .ws-img {
            width: 70px;
            height: 70px;
            top: 24px;
            right: 24px;
        }

        .question-btns-list {
            gap: 6px;
        }
    }   
</style>