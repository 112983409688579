<template>
  <section :class="this.$store.state.currentLang" class="start-section" v-if="data != null">
    <div class="container start__wrapper">
      <div class="start-header">
        <h2 class="start-title" v-html="data?.title"></h2>
        <div class="start-subtitle" v-html="data?.text"></div>
      </div>
      <div class="start-content">
        <div v-html="data?.list"></div>
        <Button
          @click="popupOpen"
          :btn_class="'blue'"
          :value="data?.btn_text"
          class="start-btn"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
export default {
  components: { Button },
  props: {
    data: null,
  },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'форма “с чего начать?”');
      this.$store.commit("openPopup", true);
    },
  },
};
</script>
<style>
.start-section {
  background-color: var(--sky);
  border-radius: 64px;
  padding: 86px 0 64px;
}
.start__wrapper {
  display: flex;
  gap: 90px;
}
.start-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;
}
.start-title {
}
.start-subtitle {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 172%; /* 27.52px */
}
.start-content {
  display: flex;
  flex-direction: column;
  gap: 37px;
  width: 100%;
}
.start-content ol {
  padding-left: 34px;
}
.start-content ol,
.start-content ol li {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 700;
  line-height: 164%; /* 45.92px */
}
@media screen and (max-width: 1200px) {

  .kz .start-content ol li {
    color: #242424;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 164%;
  }

  .start-section {
    border-radius: 32px;
    padding: 33px 0 24px;
  }
  .start__wrapper {
    flex-direction: column;
    gap: 24px;
  }
  .start-header {
    width: 100%;
    gap: 12px;
  }
  .start-title {
    font-size: 24px;
  }
  .start-subtitle {
    font-size: 13px;
  }
  .start-content {
    gap: 35px;
  }
  .start-content ol {
    padding-left: 22px;
  }
  .start-content ol,
  .start-content ol li {
    font-size: 18px;
  }
  .button.start-btn {
    width: 100%;
    font-size: 14px;
    padding: 24px;
  }
  .uz_cyr .start-content ol li {
    font-size: 16px;
  }
}
</style>
