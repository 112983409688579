<template>
  <section :class="this.$store.state.currentLang" id="recipe" v-if="data != null">
    <div class="container recipe__wrapper">
      <div class="recipe__header">
        <h2 class="recipe__header-title" v-html="data?.title"></h2>
        <div class="recipe__header-subcontent">
          <div class="recipe__header-subcontent-text" v-html="data?.subtitle"></div>
          <Button
            :btn_class="'blue'"
            :value="data?.btn_text"
            class="recipe-btn desktop"
            @click ="openRedirect"
          />
          <Button
            :btn_class="'white'"
            :value="data?.btn_text"
            class="recipe-btn mobile"
            @click ="openRedirect"
          />
        </div>
      </div>
      <div class="recipe-block" v-for="(item, index) in data?.recipes" :class="(index + 1) % 2 == 0 ? 'reverse' : ''">
        <div class="recipe-block-img">
          <img :src="item?.img" alt="" />
        </div>
        <div class="recipe-block-content">
          <div class="recipe-block-header">
            <div class="recipe-block-title" v-html="item?.title"></div>
            <div class="recipe-block-text" v-html="item?.text"></div>
          </div>
          <Button
            @click = "openRedirectProduct(item?.link)"
            :btn_class="'blue-stroke'"
            :value="item.btn_text"
            class="recipe-btn"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "@/components/UI/Button.vue";
export default {
  props: {
    data: null
  },
  components: { Button },
  methods: {
    openRedirect() {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", this.data?.link);
    },
    openRedirectProduct(link) {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", link);
    }
  }
};
</script>
<style>
.recipe__wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.recipe__header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.recipe__header-subcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recipe__header-subcontent-text {
  width: 50%;
  color: var(--gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 172%; /* 27.52px */
}
.recipe-block {
  display: flex;
  align-items: center;
  gap: 65px;
}
.recipe-block.reverse {
  flex-direction: row-reverse;
}
.recipe-block-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 570px;
  background-color: var(--biege);
  border-radius: 32px;
  height: 100%;
  padding: 25px 90px;
}
.recipe-block-img img {
  width: 100%;
  object-fit: cover;
}
.recipe-block-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
}
.recipe-block-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.recipe-block-title {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 700;
  line-height: 124%; /* 52.08px */
}
.recipe-block-text {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 164%; /* 26.24px */
}
.recipe-block.reverse .recipe-block-text {
  width: 85%;
}

@media screen and (max-width: 1200px) {
  .kz .recipe-block-text p,
  .uz .recipe-block-text p {
    width: 100%;
    color: #121212;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
  }

  .recipe-block.reverse .recipe-block-text {
    width: 100%;
  }

  .recipe__header-subcontent {
    flex-direction: column;
    gap: 32px;
  }
  .recipe__header-subcontent-text {
    width: 100%;
    font-size: 13px;
    line-height: 164%; /* 21.32px */
  }
  .recipe-block,
  .recipe-block.reverse {
    flex-direction: column;
    gap: 32px;
  }
  .recipe-block-title {
    font-size: 22px;
  }
  .recipe-block-text {
    font-size: 13px;
  }
  .recipe-block-content {
    gap: 36px;
  }
  .recipe-block-img {
    height: auto;
    padding: 28px 64px;
  }
  .recipe-block-img img {
    max-width: 230px;
  }
  .button.recipe-btn {
    width: 100%;
  }

  .uz_cyr .recipe__header-title {
    color: #121212;
    font-family: Montserrat;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: 0;
  }

  .kg .recipe__header-title {
    font-size: 23px;
  }

  .uz_cyr .recipe-block-text p {
    color: #121212;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
  }
}
</style>
