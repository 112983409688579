<template>
  <section :class="this.$store.state.currentLang" id="business" v-if="data != null">
    <div class="container">
      <div class="business-idea__wrapper">
        <h2 class="business-idea-title" v-html="data?.title"></h2>
        <div class="business-idea-content">
          <div class="business-idea-list" v-html="data?.text"></div>
          <Button
            @click="popupOpen"
            class="business-btn"
            :btn_class="'white-stroke'"
            :value="data?.btn_text"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
export default {
  props: {
    data: null
  },
  components: {
    Button,
  },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'форма “Готовая бизнес-идея”');
      this.$store.commit("openPopup", true);
    },
  },
};
</script>
<style>
.business-idea__wrapper {
  padding: 46px 72px 58px;
  border-radius: 64px;
  background-color: var(--blue);
  display: flex;
  gap: 132px;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.business-idea__wrapper::before {
  content: "";
  position: absolute;
  width: 205px;
  height: 205px;
  border: 12px solid rgba(255, 255, 255, 0.08);
  border-radius: 50%;
  top: 50px;
  left: 300px;
}
.business-idea__wrapper::after {
  content: "";
  position: absolute;
  width: 205px;
  height: 205px;
  border: 12px solid rgba(255, 255, 255, 0.08);
  border-radius: 50%;
  top: 180px;
  left: 360px;
}
.business-idea-title {
  font-size: 48px;
  color: var(--white);
}
.business-idea-content {
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 43px;
  justify-content: space-between;
}
.business-idea-list ul {
  padding-left: 25px;
}
.business-idea-list,
.business-idea-list ul,
.business-idea-list ul li,
.business-idea-list p {
  color: var(--white);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%; /* 26.24px */
}
.business-btn {
  padding: 16px 24px;
}

@media screen and (max-width: 1200px) {
  .business-idea__wrapper {
    margin-left: -20px;
    width: calc(100% + 40px);
    flex-direction: column;
    padding: 50px 20px 24px;
    gap: 32px;
    border-radius: 32px;
  }
  .business-idea-title {
    font-size: 32px;
    line-height: 108%; /* 34.56px */
  }

  .kg .business-idea-title {
    font-size: 28px;
  }

  .business-idea-content {
    padding-top: 0px;
    gap: 36px;
  }
  .business-idea-list,
  .business-idea-list ul,
  .business-idea-list ul li,
  .business-idea-list p {
    font-size: 13px;
  }
  .business-idea__wrapper::before {
    width: 120px;
    height: 120px;
    top: 75px;
    left: unset;
    right: -33px;
  }
  .business-idea__wrapper::after {
    width: 120px;
    height: 120px;
    top: 130px;
    left: unset;
    right: 28px;
  }
  .business-idea-content .button {
    width: 100%;
    padding: 24px;
    font-size: 14px;
  }
}
</style>
