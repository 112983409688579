<template>
  <section :class="this.$store.state.currentLang" id="history-section" v-if="data != null">
    <div class="container history-container">
      <div class="top-span">{{ data?.top_title }}</div>
      <h2 v-html="data?.title"></h2>
      <div class="list-history">
        <template v-for="(item, index) in data?.items">
          <a
            class="history-item"
            :href="item?.link"
            target="_blank"
            v-if="item?.link"
          >
            <img class="desktop" :src="item?.img" alt="" />
            <img class="mobile" :src="item?.img_m" alt="" />
            <span>{{ item?.name }}</span>
            <p v-html="item?.post"></p>
          </a>
          <div
            class="history-item"
            v-else
          >
            <img class="desktop" :src="item?.img" alt="" />
            <img class="mobile" :src="item?.img_m" alt="" />
            <span>{{ item?.name }}</span>
            <p v-html="item?.post"></p>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: null,
  },
};
</script>
<style>
#history-section {
  margin-bottom: 252px;
}

.history-container h2 {
  margin-bottom: 48px;
}

.list-history {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.history-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.history-item img {
  width: 100%;
  height: auto;
  border-radius: 36px;
  margin-bottom: 24px;
}

.history-item span {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  transition: 0.5s;
}

.history-item p {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 156%;
  letter-spacing: 0.28px;
  transition: 0.5s;
}

.history-item:hover span,
.history-item:hover p {
  color: var(--blue);
}

@media screen and (max-width: 1200px) {
  #history-section {
    margin-bottom: 126px;
  }
  .list-history {
    grid-template-columns: 1fr;
    gap: 48px;
  }

  .history-item img {
    border-radius: 36px;
    margin-bottom: 20px;
  }

  .history-item span {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
  }

  .history-item p {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 156%;
    letter-spacing: 0.26px;
  }

  .history-item {
    gap: 2px;
  }

  .history-container h2 {
    margin-bottom: 36px;
  }

  .uz_cyr .history-container h2 {
    color: #242424;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
  }
}
</style>