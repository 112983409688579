<template>
  <div :class="'popup ' + ($store.state[type] ? 'active' : '')">
    <div class="popup-bg" @click="popupClose()"></div>
    <div class="popup-container">
      <div class="popup-header">
        <slot name="header"> </slot>
        <svg
          @click="popupClose()"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            opacity="0.72"
            d="M16.4349 0L20 3.56506L13.6898 9.87522L19.9287 16.1141L16.1141 19.9287L9.87522 13.6898L3.56506 20L0 16.4349L6.31016 10.1248L0.0713003 3.88592L3.88592 0.0713003L10.1248 6.31016L16.4349 0Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="popup-content">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: "",
  },
  methods: {
    popupClose() {
      this.$store.commit(this.type, false);
      this.$emit("reset");
    },
  }, 
  emit: ['reset'],
};
</script>
<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100lvh;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup.active {
  opacity: 1;
  pointer-events: all;
}

.popup-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
}

.popup-container {
  position: relative;
  width: 410px;
  background-color: var(--blue);
  height: auto;
  z-index: 100;
  padding: 50px 45px;
  border-radius: 32px;
}

.popup-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.popup-header h3 {
  text-align: center;
  color: var(--white);
}

.popup-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popup-header svg {
  position: absolute;
  top: 46px;
  right: 46px;
  cursor: pointer;
}
</style>
