<template>
  <a :href="link" class="button" :class="btn_class" target="_blank" v-html="value"></a>
</template>
<script>
export default {
  name: "Button",
  props: {
    link: "",
    btn_class: "",
    value: "",
  },
};
</script>
<style>
.button {
  height: max-content;
  display: flex;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  white-space: normal;
  border-radius: 16px;
  transition: 0.5s;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 132%;
  border: 1px solid transparent;
  cursor: pointer;
  width: fit-content;
  text-align: center;
}
.button.blue {
  background-color: var(--blue);
  color: var(--white);
  text-transform: uppercase;
}
.button.blue:hover {
  background-color: rgba(10, 151, 160, 0.84);
}
.button.white {
  background-color: var(--white);
  border: 1px solid var(--blue);
  color: var(--blue);
  text-transform: uppercase;
}
.button.white:hover {
  background-color: var(--blue);
  color: var(--white);
  border: 1px solid var(--white);
}
.button.blue-stroke {
  background-color: transparent;
  border: 1px solid var(--blue);
  color: var(--blue);
  text-transform: uppercase;
}
.button.blue-stroke:hover {
  background-color: var(--blue);
  color: var(--white);
}
.button.white-stroke {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}
.button.white-stroke:hover {
  background-color: var(--white);
  color: var(--dark_gray);
}
@media screen and (max-width: 1200px) {
  .button {
    padding: 16px 24px;
  }
}
</style>
