<template>
  <section :class="this.$store.state.currentLang" id="bonuses-section" v-if="data != null">
    <div class="container bonuses__wrapper">
      <div class="bonuses-header">
        <h2 class="bonuses-title" v-html="data?.title"></h2>
        <h3 class="bonuses-subtitle" v-html="data?.subtitle"></h3>
        <Button
          class="bonuses-btn"
          :btn_class="'blue'"
          :value="data?.btn_text"
          @click = "openRedirect"
        />
      </div>
      <div class="bonuses-cards">
        <div
          class="bonuses-card"
          v-for="(card, index) in data?.cards"
          :class="index + 1 == 3 ? 'blue' : ''"
        >
          <div class="bonuses-card-step">{{ index + 1 }} {{ data?.step }}</div>
          <div class="bonuses-card-text" v-html="card.text"></div>
          <img
            v-if="index + 1 == 3"
            class="bonuses-card-img"
            :src="require('@/assets/img/hand.svg')"
            alt=""
          />
        </div>
      </div>
      <Button
        :value="data?.btn_text"
        :btn_class="'blue'"
        class="mobile"
        @click = "openRedirect"
      />
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";
export default {
  components: { Button },
  props: {
    data: null,
  },
  methods: {
    openRedirect() {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", this.data?.register);
    },
  }
};
</script>
<style>
#bonuses-section {
  margin-bottom: 240px;
}
.bonuses__wrapper {
  display: flex;
  flex-direction: column;
  gap: 34px;
}
.bonuses-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.button.bonuses-btn.mobile {
  display: none;
}
.button.bonuses-btn {
  margin-top: 20px;
  width: fit-content;
}

.bonuses-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}
.bonuses-card {
  position: relative;
  padding: 24px;
  background-color: var(--biege);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;
}
.bonuses-card.blue {
  background-color: var(--blue);
}
.bonuses-card-step {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 102%; /* 42.84px */
}
.bonuses-card.blue > div {
  color: var(--white);
}
.bonuses-card-text {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 136%; /* 27.2px */
}
.bonuses-card-img {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 95px;
  height: 95px;
  object-fit: contain;
}
.bonuses-card:nth-child(1) {
  translate: 0 60px;
}
.bonuses-card:nth-child(3) {
  translate: 0 -60px;
}
.bonuses-card:nth-child(4) {
  translate: 0 -120px;
}
@media screen and (max-width: 1200px) {
  .bonuses__wrapper {
    gap: 48px;
  }
  .bonuses-header {
    gap: 12px;
  }
  .bonuses-title br {
    display: none;
  }
  .bonuses-title {
    font-size: 24px;
    line-height: 124%; /* 29.76px */
  }
  .bonuses-card {
    gap: 46px;
  }
  .bonuses-card-step {
    font-size: 36px;
  }
  .bonuses-card-text {
    font-size: 16px;
  }
  .uz .bonuses-card-text {
    font-size: 15px;
  }
  .bonuses-card:nth-child(1),
  .bonuses-card:nth-child(3),
  .bonuses-card:nth-child(4) {
    translate: 0 0;
  }
  .bonuses-card-img {
    width: 67px;
    height: 67px;
  }
  .button.bonuses-btn {
    display: none;
  }
  .mobile {
    width: 100%;
    display: block;
  }

  .bonuses-card {
    padding: 16px;
  }
}
</style>
