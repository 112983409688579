<template>
    <form @submit="formSending" ref="form">
        <slot></slot>
    </form>
</template>
<script>
    import axios from 'axios'
    export default {
        props: {
            url: '',
            method: 'POST'
        },
        methods:{
            async formSending(e) {
                e.preventDefault();
                const form = this.$refs.form;
                try {
                    // submitExpert.setAttribute('disabled', 'disabled');
                    let response;
                    switch (this.method) {
                        case 'POST':
                            response = await axios.post(this.url, form);
                            break;
                        case 'GET':
                            response = await axios.get(this.url, form);
                            break;
                        case 'PUT':
                            response = await axios.put(this.url, form);
                            break;
                        case 'DELETE':
                            response = await axios.delete(this.url, form);
                            break;
                    }
                    if (response.status == 200) {
                        const data = response.data;
                    }
                    else {
                    }
                } catch (error) {
                }
                finally {
                    // setTimeout(() => {
                    //     submitExpert.removeAttribute('disabled');
                    // }, 5000);
                    e.target.reset();
                }
            }
        }
    }
</script>
<style>
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        cursor: pointer;
    }
</style>