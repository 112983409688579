<template>
  <section :class="this.$store.state.currentLang" id="largest" v-if="data != null">
    <div class="container">
      <div class="largest-title">
        <h2 v-html="data?.title"></h2>
        <Button @click="openRedirect" class="desktop" btn_class="blue" :value="data?.btn_text" />
      </div>
      <div :class="'largest-row' + row.items.length" v-for="row in data?.rows">
        <div class="largest-item" v-for="item in row.items">
          <hr />
          <span>{{ item.title }}</span>
          <p v-html="item.text"></p>
        </div>
      </div>
      <Button @click="openRedirect" class="mobile" btn_class="blue" :value="data?.btn_text" />
    </div>
  </section>
</template>
<script>
import Button from '../UI/Button.vue';

export default {
    components: {
      Button
    },
    props: {
      data: null,
    },
    methods: {
      openRedirect() {
        this.$store.commit("openRedirect", true);
        this.$store.commit("setPopupLink", 'https://www.taomed.ru/');
      }
    }
};
</script>
<style scoped>
.largest-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 56px;
}

.largest-title h2 {
  max-width: 749px;
}
.largest-row3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  margin-bottom: 40px;
}

.largest-row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.largest-item hr {
  margin-bottom: 24px;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: var(--blue);
  border: none;
}

.largest-item span {
  display: block;
  color: var(--gray);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  margin-bottom: 8px;
}

.largest-item p {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%; /* 26.24px */
}

.kg .largest-item p {
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .largest-title {
    margin-bottom: 36px;
  }

  .largest-row3,
  .largest-row2 {
    grid-template-columns: 1fr;
  }

  .largest-item {
    margin-bottom: 36px;
  }

  .largest-item hr {
    margin-bottom: 16px;
  }

  .largest-item span {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    margin-bottom: 6px;
  }

  .largest-row3 {
    margin-bottom: 36px;
  }

  .largest-item:last-of-type {
    margin-bottom: 0px;
  }

  .largest-item p,
  .kg .largest-item p {
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
  }

  .button.mobile {
    display: none;
  }

  #largest .button.mobile {
    display: block;
    margin-top: 23px;
    width: 100%;
    text-align: center;
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    text-transform: uppercase;
    padding: 16px 36px;
  }
}
</style>
