<template>
  <section :class="this.$store.state.currentLang" id="path-section" v-if="data != null">
    <div class="container path">
      <div class="top-span" v-html="data?.top_title"></div>
      <h2 v-html="data?.title"></h2>
      <div class="path-text">
        <div class="path-text-left">
          <div class="path-text-left-container" v-html="data?.text_left"></div>
          <Button
            @click = 'openRedirect'
            btn_class="blue-stroke"
            :value="data?.btn_text_left"
            class="path-btn"
          />
        </div>
        <div class="path-text-right">
          <div class="path-text-right-container" v-html="data?.text_right"></div>
          <Button
            @click="popupOpen"
            btn_class="blue"
            :value="data?.btn_text_right"
            class="path-btn"
          />
        </div>
      </div>
      <svg
        class="desktop"
        width="100%"
        height="380"
        viewBox="0 0 1244 380"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.666666 210C0.666666 221.782 10.2179 231.333 22 231.333C33.7821 231.333 43.3333 221.782 43.3333 210C43.3333 198.218 33.7821 188.667 22 188.667C10.2179 188.667 0.666666 198.218 0.666666 210ZM1200.67 49.5C1200.67 61.2821 1210.22 70.8333 1222 70.8333C1233.78 70.8333 1243.33 61.2821 1243.33 49.5C1243.33 37.7179 1233.78 28.1667 1222 28.1667C1210.22 28.1667 1200.67 37.7179 1200.67 49.5ZM882.251 361.398L880.413 364.951L882.251 361.398ZM891.405 360.123L894.144 363.038L891.405 360.123ZM403.896 113.903L402.058 117.456L403.896 113.903ZM22.9961 213.874L399.223 117.134L397.231 109.386L21.0039 206.126L22.9961 213.874ZM402.058 117.456L880.413 364.951L884.089 357.846L405.734 110.35L402.058 117.456ZM894.144 363.038L1224.74 52.4151L1219.26 46.5849L888.666 357.208L894.144 363.038ZM880.413 364.951C884.932 367.289 890.435 366.523 894.144 363.038L888.666 357.208C887.43 358.37 885.595 358.625 884.089 357.846L880.413 364.951ZM399.223 117.134C400.175 116.889 401.184 117.004 402.058 117.456L405.734 110.35C403.114 108.995 400.087 108.652 397.231 109.386L399.223 117.134Z"
          fill="#0A97A0"
        />
        <circle cx="401" cy="113" r="16" fill="#0A97A0" />
        <circle cx="885" cy="364" r="16" fill="#0A97A0" />
        <foreignObject class="step" x="20" y="279">
          <div class="step">
            <span>{{ data?.steps[0]?.title }}</span>
            <div v-html="data?.steps[0]?.text"></div>
          </div>
        </foreignObject>
        <foreignObject class="step" x="316" y="168">
          <div class="step">
            <span>{{ data?.steps[1]?.title }}</span>
            <div v-html="data?.steps[1]?.text"></div>
          </div>
        </foreignObject>
        <foreignObject class="step" x="737" y="170">
          <div class="step">
            <span>{{ data?.steps[2]?.title }}</span>
            <div v-html="data?.steps[2]?.text"></div>
          </div>
        </foreignObject>
        <foreignObject ref="registr" class="step" x="933" y="5">
          <div class="step">
            <span>{{ data?.steps[3]?.title }}</span>
            <div v-html="data?.steps[3]?.text"></div>
          </div>
        </foreignObject>
        <!-- <text class="step" width="100px" x="22" y="279">{{ data?.steps[0]?.title }}</text>
        <text class="step" width="100px" x="22" y="299">{{ data?.steps[0]?.text }}</text>
        <text class="step" width="100px" x="318" y="168">{{ data?.steps[1]?.title }}</text>
        <text class="step" width="100px" x="318" y="188">{{ data?.steps[0]?.text }}</text>
        <text class="step" width="100px" x="739" y="190">{{ data?.steps[2]?.title }}</text>
        <text class="step" width="100px" x="739" y="210">{{ data?.steps[0]?.text }}</text>
        <text class="step" width="100px" x="935" y="5">{{ data?.steps[3]?.title }}</text>
        <text class="step" width="100px" x="935" y="25">{{ data?.steps[0]?.text }}</text> -->
      </svg>
      <svg
        class="mobile"
        width="100%"
        height="558"
        viewBox="0 0 353 558"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 0C7.16345 0 0 7.16344 0 16C0 24.8366 7.16345 32 16 32C24.8365 32 32 24.8366 32 16C32 7.16344 24.8365 0 16 0ZM337 519C328.163 519 321 526.163 321 535C321 543.837 328.163 551 337 551C345.837 551 353 543.837 353 535C353 526.163 345.837 519 337 519ZM40.6429 379.752L42.6191 382.009L40.6429 379.752ZM42.4385 392.977L41.1356 395.68L42.4385 392.977ZM266.484 168.174L268.041 165.61L266.484 168.174ZM267.6 181.03L265.624 178.773L267.6 181.03ZM14.4424 18.5639L264.926 170.738L268.041 165.61L17.5576 13.4361L14.4424 18.5639ZM265.624 178.773L38.6666 377.495L42.6191 382.009L269.576 183.287L265.624 178.773ZM41.1356 395.68L335.697 537.702L338.303 532.298L43.7414 390.275L41.1356 395.68ZM38.6666 377.495C32.7342 382.69 34.0329 392.255 41.1356 395.68L43.7414 390.275C40.5129 388.718 39.9226 384.371 42.6191 382.009L38.6666 377.495ZM264.926 170.738C267.814 172.493 268.166 176.547 265.624 178.773L269.576 183.287C275.169 178.39 274.395 169.47 268.041 165.61L264.926 170.738Z"
          fill="#0A97A0"
        />
        <circle cx="39" cy="385" r="16" fill="#0A97A0" />
        <circle cx="271" cy="175" r="16" fill="#0A97A0" />
        <foreignObject class="step" x="129" y="0">
          <div class="step">
            <span>{{ data?.steps[0]?.title }}</span>
            <div v-html="data?.steps[0]?.text"></div>
          </div>
        </foreignObject>
        <foreignObject class="step" x="0" y="140">
          <div class="step">
            <span>{{ data?.steps[1]?.title }}</span>
            <div v-html="data?.steps[1]?.text"></div>
          </div>
        </foreignObject>
        <foreignObject class="step" x="130" y="309">
          <div class="step">
            <span>{{ data?.steps[2]?.title }}</span>
            <div v-html="data?.steps[2]?.text"></div>
          </div>
        </foreignObject>
        <foreignObject ref="registr2" class="step" x="47" y="481">
          <div class="step">
            <span>{{ data?.steps[3]?.title }}</span>
            <div v-html="data?.steps[3]?.text"></div>
          </div>
        </foreignObject>
      </svg>
    </div>
  </section>
</template>
<script>
import Button from "@/components/UI/Button.vue";

export default {
  components: {
    Button,
  },
  props: {
    data: null,
  },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'Форма в “Дальнейший путь с ТАОВИТА”');
      this.$store.commit("openPopup", true);
    },
    openRedirect() {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", this.data?.link_left);
    },
  },
  watch: {
    data(new_value) {
      const interval = setInterval(() => {
        if (this.$refs.registr && this.$refs.registr2) {
          const link = this.$refs.registr.querySelector('a');
          const link2 = this.$refs.registr2.querySelector('a');
          
          if (link) {   
            link.addEventListener('click', ()=> {
              this.$store.commit("openRedirect", true);
              this.$store.commit("setPopupLink", this.data?.link_right);
            })
          }
          if (link2) {
            link2.addEventListener('click', () => {
              this.$store.commit("openRedirect", true);
              this.$store.commit("setPopupLink", this.data?.link_right);
            })
          }
          clearInterval(interval);
        }
      }, 50);
    }
  }

};
</script>
<style>
.path-text {
  margin-top: 28px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.path-text-left,
.path-text-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 42px;
}


.step {
  width: 255px;
  height: 100px;
  position: fixed;
}

.kg .step {
  height: 115px;
}

.step span {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 124%;
}

.step p {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 148%;
  letter-spacing: 0.26px;
  white-space: initial;
}

.step a {
  cursor: pointer;
  color: var(--blue);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 148%;
  letter-spacing: 0.26px;
  text-decoration-line: underline;
}

.path-text .button {
  font-size: 14px;
}

.uz_cyr .path-text-left p,
.uz_cyr .path-text-right p {
  color: #242424;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
  letter-spacing: 0.26px;
}

@media screen and (max-width: 1200px) {
  .kz .top-span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  #path-section {
    margin-bottom: 124px;
  }
  .path-text {
    grid-template-columns: 1fr;
    margin-top: 12px;
    gap: 48px;
    margin-bottom: 64px;
  }

  .step span {
    color: #363636;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 124%;
  }

  .step p {
    color: #484848;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;
  }

  .path-text-left,
  .path-text-right {
    gap: 24px;
  }
  .path-text-left p,
  .path-text-right p {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
    width: calc(100vw - 40px);
  }
  .path-btn {
    width: 100%;
  }

  .uz_cyr .path-text-left p,
  .uz_cyr .path-text-right p {
    font-size: 12px;
    letter-spacing: 0;
  }

  .uz_cyr .step p {
    font-size: 11px;
  }

  .uz_cyr .step span {
    font-size: 12px;
  }
}
</style>
